
import './about.scss'
import Button from '../../components/includes/button';

import AboutImg from '../../assets/img/about.png'
import AboutImgBottom from '../../assets/img/aboutBottom.png'
import AboutGreen from '../../assets/img/aboutGreen.png'

import {ReactComponent as Business} from '../../assets/svg/aboutBusiness.svg'
import {ReactComponent as Fuel} from '../../assets/svg/aboutFuel.svg'
import {ReactComponent as Lock} from '../../assets/svg/aboutLock.svg'
import {ReactComponent as Save} from '../../assets/svg/aboutSave.svg'
import {ReactComponent as Shield} from '../../assets/svg/aboutShield.svg'
import {ReactComponent as Tow} from '../../assets/svg/aboutTow.svg'
import {ReactComponent as User} from '../../assets/svg/aboutUser.svg'

import CultureItem from '../../components/cultureItem';
import { useNavigate } from 'react-router-dom';


export default function About() {
    const navigate = useNavigate();



    return (
		<div className="about-page">
			<div className="our-mission">
				<div className="title">
					<h3>Our Mission</h3>
					<h1>Our Mission</h1>
				</div>
				<div className="hero-wrapper">
					<div className="about-text-our-mission">
						<p>
							Our mission is using modern technology to comfort stranded
							motorists by accelerating the process of securing safe, low
							cost, and service oriented towing providers.
						</p>
						<br />
						<p>Tow Providers! Join us using the "Sign up" button above!</p>
						{/* <Button
							className="outlined-button"
							text="JOIN US"
							onClick={(e) => {
								e.preventDefault();
								navigate("/signin");
								console.log("clicked");
							}}
						/> */}
					</div>
					<img src={AboutImg} alt="" />
				</div>
			</div>

			<div className="our-vision">
				<div className="title">
					<h3>Our Vision</h3>
					<h1>Our Vision</h1>
				</div>
				<div className="vision-board">
					<div className="vision-item">
						<Tow />
						<h3>Service Network</h3>
						<p>
							Create a national Service Network of tow providers for
							stranded motorists to choose from.
						</p>
					</div>
					<div className="vision-item">
						<Business />
						<h3>Low Cost</h3>
						<p>
							Create visibility on the lowest cost tow providers for
							stranded motorists to choose from.
						</p>
					</div>
					<div className="vision-item">
						<Lock />
						<h3>Safe and Secure</h3>
						<p>
							Provide stranded motorists with visibility on the service and
							safety history of tow providers.
						</p>
					</div>
					<div className="vision-item">
						<Fuel />
						<h3>Fast and Efficient</h3>
						<p>
							Provide stranded motorist with visibility of the closest tow
							provider who can service their needs.
						</p>
					</div>
				</div>
			</div>

			<div className="green-statement">
				<div className="title">
					<h3>Green Statement</h3>
					<h1>Green Statement</h1>
				</div>
				<div className="green-wrapper">
					<img src={AboutGreen} alt="" />
					<div className="green-item-wrapper">
						<div className="green-item">
							<User />
							<p>
								Minimize the use of the world's fossil fuel resources by
								connecting stranded motorists to the closest towing
								resources available.
							</p>
						</div>
						<div className="green-item">
							<Shield />
							<p>
								Eradicate the number of follow-on accidents and injuries
								caused by immobilized vehicles remaining in high traffic
								areas for long periods of time.
							</p>
						</div>
						<div className="green-item">
							<Save />
							<p>
								Minimize the time and resources required to secure a tow.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="our-culture">
				<div className="title">
					<h3>Our Culture</h3>
					<h1>Our Culture</h1>
				</div>

				<CultureItem
					title="Diversity"
					text="A genuine deep commitment and love for diversity"
				/>
				<CultureItem
					title="Conservation"
					text="A genuine deep commitment and love for conservation"
				/>
				<CultureItem
					title="Serving"
					text="A genuine deep commitment and love for serving"
				/>
				<CultureItem
					title="Learning"
					text="A genuine deep commitment and love for learning"
				/>
			</div>

			<div className="our-history">
				<div className="title">
					<h1>Our History</h1>
					<h3>Our History</h3>
				</div>
				<div className="our-history-wrapper">
					<img src={AboutImgBottom} alt="" />
					<p>
                        <p>
                            “Creativity is just connecting things.” Steve Jobs
						</p>
						<p>
                            When a vehicle brakes down, drivers and passengers are vulnerable. They need
                            support to find solutions that quickly get them to safety and back
                            on the road. Every second they sit on the side of the road waiting
                            is time away from their families, their jobs, and their
                            commitments. Further, immobilized vehicles impact traffic and are
                            35% more likely to cause secondary accidents requiring more
                            emergency vehicles and causing further delays. 
                        </p>
						<p>
							More than 285 million vehicles are on the road and close to 20,000 car accidents
							occur every day; yet, no towing company stands out as the safest,
							lowest cost or best service provider. When help is needed stranded
							motorists must search the internet or waste valuable time waiting
							on insurance companies using call centers to search the internet
							for a tow provider. This often leads to hours of waiting on the
							side of the road which is unsafe and a massive waste of time. In
							rural areas it can take even longer due to lower internet or cell
							coverage bandwidth. Tow truck companies are limited, especially in
							rural areas, making it harder for independent operators to
							profitably compete and market their services. The current
							situation isn’t sustainable and wastes precious world resources.
						</p>
						<p>
							At Text-a-Tow we believe technology provides an answer to these
							challenges and that competition and transparency increase safety
							and speed to stranded motorists. Within minutes text-a-tow helps
							you secure the local service you need from a low cost provider
							that is close with a positive track record for safety and service.
							Using Text-a-Tow you can get your loved ones to a safe place
							quicker. Text-a-tow makes it possible while minimizing required
							fossil fuel resources.
						</p>
					</p>
				</div>
			</div>
		</div>
	);
}
