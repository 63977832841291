import './index.scss';

import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/api/index.js';
import { getAbbreviatedStateName, getStateFlag } from './stateFlags.js';
import { csrfToken } from '../../utils/cookies';
import ScaleLoader from 'react-spinners/ScaleLoader';

export default function StateInfo({ stateNames, stateInfo = 'There are 100+ service providers in Arizona. With ~526 Zip Codes.' }) {
    const api = useApi();

    const [stateName, setStateName] = useState('');
    const [flagUrl, setFlagUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [serviceProviders, setServiceProviders] = useState([
        {
            name: "I'm sorry, there was an error retrieving providers.",
            address: 'Try reloading the page.',
            services: [''],
            zipCoverage: [''],
        },
    ]);

    // still to be set up completely...
    const getProviders = async (sn) => {
        setLoading(true);
        await api
            .get({
                url: `/state-providers/?address__state=${sn}`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken,
                },
            })
            .then((results) => {
                if (results.success) {
                    // filter through returned data.
                    if (results.response.data.count === 0) {
                        setServiceProviders([
                            {
                                name: "I'm sorry, there are no service providers in this state yet.",
                                address: { line1: 'Become our first by clicking the "Sign Up" button in the top right corner.' },
                                services: [''],
                                zipCoverage: [''],
                            },
                        ]);
                    } else {
                        setServiceProviders(results.response.data.results);
                    }
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setServiceProviders([
                    {
                        name: "I'm sorry, there was an error retrieving providers.",
                        address: { line1: 'Try reloading the page.' },
                        services: [''],
                        zipCoverage: [''],
                    },
                ]);
                setLoading(false);
            });
    };

    useEffect(() => {
        setFlagUrl(getStateFlag(stateNames.short));
        setStateName(stateNames.long);

        getProviders(stateNames.short);
    }, [stateNames]);

    return (
        <div className='state-info-page'>
            <div
                className='flag-banner'
                style={{
                    backgroundImage: `url(${flagUrl})`,
                }}
            >
                <h1 className='state-page-title'>{stateName}</h1>
                <div className='flag-banner-text'>{stateInfo}</div>
            </div>
            <div className='state-service-providers'>
                <h1 className='state-page-title sp'>Service providers of {stateName} working with Textatow</h1>
                <div className='service-providers'>
                    {loading ? (
                        <ScaleLoader color='#0093d0' />
                    ) : (
                        <>
                            {serviceProviders.map((provider, index) => (
                                <div key={index} className='service-provider-content'>
                                    <h3>{provider.name}</h3>
                                    <p>
                                        <span>
                                            <u>Address:</u>
                                        </span>
                                        {provider.address.line1}, {provider.address.city}, {provider.address.state} {provider.address.zip}
                                    </p>
                                    <p>
                                        <span>
                                            <u>Services:</u>
                                        </span>
                                        {provider.services.map((service, index) => (
                                            <span key={index}>{service}, </span>
                                        ))}
                                    </p>
                                    <p>
                                        <span>
                                            <u>ZipCoverage:</u>
                                        </span>
                                        {provider.zipCoverage.map((zip, index) => (
                                            <span key={index}>{zip}, </span>
                                        ))}
                                    </p>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
