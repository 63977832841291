import { useState, useEffect } from 'react';
import { ReactComponent as PlusBox } from "../../../assets/svg/plus.svg";
import { ReactComponent as MinusBox } from "../../../assets/svg/minus.svg";
import TimeSelector from '../timeSelector';

const TimeController = ({ day, handleSetCompanySchedule }) => {

        
    const [companyTimes, setCompanyTimes] = useState({ [day]: [{ from: "", to: "" }] });

    const addTimeSlot = (day) => {
        setCompanyTimes((prevTimes) => ({
            ...prevTimes,
            [day]: [...prevTimes[day], { from: "", to: "" }],
        }));
    };

    const updateTimeSlot = (day, index, updatedTimeSlot) => {
        setCompanyTimes((prevTimes) => ({
            ...prevTimes,
            [day]: prevTimes[day].map((timeSlot, i) =>
                i === index ? updatedTimeSlot : timeSlot
            ),
        }));
    };

    const removeTimeSlot = (day, index) => {
        setCompanyTimes((prevTimes) => ({
            ...prevTimes,
            [day]: prevTimes[day].filter((_, i) => i !== index),
        }));
    };

    useEffect(() => {
        handleSetCompanySchedule(companyTimes);
    }, [companyTimes]);
    

    return (
        <>
            {companyTimes[day].map((timeSlot, index) => (
                <div className="timebox" key={index}>
                    <TimeSelector
                        from={timeSlot.from}
                        to={timeSlot.to}
                        onChange={(updatedTimeSlot) =>
                        updateTimeSlot(day, index, updatedTimeSlot)
                        }
                    />

                    {index === 0 ? (
                        <div className="time-icon" onClick={() => addTimeSlot(day)}>
                            <PlusBox  />
                        </div>
                    ) : (
                        <div className="time-icon" onClick={() => removeTimeSlot(day, index)}>
                            <MinusBox  />
                        </div>
                    )}
                </div>
            ))}
        </>
    );
}

export default TimeController;
