import './index.scss';
import React, { useEffect, useState } from 'react';
import Button from '../includes/button';
import { useAcceptJs } from 'react-acceptjs';
import { toast } from 'react-toastify';
import { usePaymentInputs } from 'react-payment-inputs';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useForm } from 'react-hook-form';
import { useApi } from '../../hooks/api';

const PaymentForm = ({ moveStep, step, order }) => {
    const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getZIPProps } = usePaymentInputs();
    const { erroredInputs } = meta;
    const [paymentStep, setPaymentStep] = useState(0);
    const [loader, setLoader] = useState(false);
    const [highEstPrice, setHighEstPrice] = useState('');
    const [estPrice, setEstPrice] = useState('');
    const [lowEstPrice, setLowEstPrice] = useState('');
    const [errors, setErrors] = useState({});
    const api = useApi();

    const [localCardData, setLocalCardData] = useState({
        cardNumber: '',
        expiryDate: '',
        expMonth: '',
        expYear: '',
        cvc: '',
        zip: '',
        fullName: '',
    });

    const authData = {
        clientKey: '8vRV8U2C6MdUtQEnuUr3G7Xq8k8MK8U34XFG8ezB9rtNrFqeePh6bbJ7EqaCVhhk',
        apiLoginID: '27GwvE3tNYY6',
    };
    const environment = 'PRODUCTION';
    const { dispatchData, loading, error } = useAcceptJs({ environment, authData });

    const handleCardDataChange = (e) => {
        const { name, value } = e.target;
        setLocalCardData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        removeError(name);
    };

    const removeError = (name) => {
        if (errors.hasOwnProperty(name)) {
            setErrors((prevState) => {
                let newState = {
                    ...prevState,
                };
                delete newState[name];
                return newState;
            });
        }
    };

    const handleFormSubmit = async (data) => {
        setLoader(true);
        // Dispatch CC data to Authorize.net and receive payment nonce for use on our server
        const authCardData = {
            cardData: {
                cardNumber: localCardData.cardNumber.replace(/\s/g, ''),
                month: localCardData.expMonth,
                year: localCardData.expYear,
                cardCode: localCardData.cvc,
                zip: localCardData.zip,
                fullName: localCardData.fullName,
            },
            bankData: undefined,
        };

        await dispatchData(authCardData)
            .then((response) => {
                console.log(response, 'response');
                api.post({
                    url: '/authnet-payments/card-validated/',
                    body: {
                        order_uuid: order.uuid,
                        paymentData: response,
                    },
                })
                    .then((results) => {
                        if (results.success) {
                            toast.success('Card Validated! Check your text messages');
                            setLoader(false);
                            moveStep(1);
                        } else {
                            setLoader(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoader(false);
                    });
            })
            .catch((err) => {
                console.log(err);
                // TODO parse and add Authorize.net input errors to state object
                toast.error(err.messages.message[0].text);
                setLoader(false);
            });
    };

    const getEstimatedPrice = async () => {
        await api
            .post({
                url: '/orders/customer-cost/',
                body: {
                    order_uuid: order.uuid,
                },
            })
            .then((results) => {
                const exactEstPrice = Math.round(parseFloat(results.response.data.estimatedCost));
                const upperValue = exactEstPrice + 25;
                const lowerValue = exactEstPrice - 25 >= 0 ? exactEstPrice - 25 : 0;
                setHighEstPrice(`$${(lowerValue + 33.33).toFixed(2)} - $${(upperValue + 33.33).toFixed(2)}`);
                setEstPrice(`$${lowerValue.toFixed(2)} - $${upperValue.toFixed(2)}`);
                setLowEstPrice(`$${(lowerValue - 33.33).toFixed(2)} - $${(upperValue - 33.33).toFixed(2)}`);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (localCardData.expiryDate.length === 4) {
            const month = localCardData.expiryDate.slice(0, 2);
            const year = localCardData.expiryDate.slice(3);
            setLocalCardData((prevData) => ({ ...prevData, expMonth: month, expYear: year }));
        }
    }, [localCardData.expiryDate]);

    useEffect(() => {
        if (paymentStep === 1) {
            moveStep(1);
        }
    }, [loading, error, paymentStep]);

    useEffect(() => {
        if (step === 6) {
            getEstimatedPrice();
        }
    }, [step]);

    useEffect(() => {
        const exactEstPrice = Math.round(parseFloat(123.70023));
        const upperValue = parseFloat(exactEstPrice + 25);
        const lowerValue = parseFloat(exactEstPrice - 25) >= 0 ? parseFloat(exactEstPrice - 25) : 0;
        setHighEstPrice(`$${(lowerValue + 33.33).toFixed(2)} - $${(upperValue + 33.33).toFixed(2)}`);
        setEstPrice(`$${lowerValue.toFixed(2)} - $${upperValue.toFixed(2)}`);
        setLowEstPrice(`$${(lowerValue - 33.33).toFixed(2)} - $${(upperValue - 33.33).toFixed(2)}`);
    }, []);

    return (
        <>
            {/* <h1>Estimated cost for service in your area:</h1>
            <h2>{estPrice || '$-'}</h2> */}
            <fieldset className='payment-fieldset'>
                <legend>Please select one of the following providers</legend>
                <div onClick={() => document.getElementById('opt1').click()}>
                    <input className='payment-fieldset-input' type='radio' name='action' id='opt1' value={highEstPrice || '$-'} />
                    <label className='payment-label' for='opt1'>
                        Option 1 ~ {highEstPrice || '$ -'}
                    </label>
                </div>
                <div onClick={() => document.getElementById('opt2').click()}>
                    <input className='payment-fieldset-input' type='radio' name='action' id='opt2' value={estPrice || '$-'} />
                    <label className='payment-label' for='opt2'>
                        Option 2 ~ {estPrice || '$ -'}
                    </label>
                </div>
                <div onClick={() => document.getElementById('opt3').click()}>
                    <input className='payment-fieldset-input' type='radio' name='action' id='opt3' value={lowEstPrice || '$-'} />
                    <label className='payment-label' for='opt3'>
                        Option 3 ~ {lowEstPrice || '$ -'}
                    </label>
                </div>
            </fieldset>
            <p>Once you request service, providers will send you offers via Text. If you accept an offer this card will be charged.</p>
            <p>a.k.a. Your card will not be charged until you accept an offer via text.</p>
            <br />
            <br />
            <p>We do not save card information.</p>
            <form>
                <h2>Enter a valid Debit or Credit Card</h2>
                <div className='form-container'>
                    <div className='input-group'>
                        <label htmlFor='fullName' hidden>
                            Name on Card
                        </label>
                        <input
                            aria-invalid={errors.fullName ? 'true' : 'false'}
                            className={errors.fullName ? 'input-box cardinfo is-invalid' : 'input-box cardinfo'}
                            type='text'
                            name='fullName'
                            placeholder='Name on Card'
                            value={localCardData.fullName}
                            onChange={handleCardDataChange}
                            maxLength={64}
                        />
                        {errors?.fullName && (
                            <span role='alert' className='input-error'>
                                {errors.fullName}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <label htmlFor='cardNumber' hidden>
                            Card Number
                        </label>
                        <input
                            aria-invalid={errors.cardNumber ? 'true' : 'false'}
                            className={errors.cardNumber ? 'input-box cardinfo cardnumber is-invalid' : 'input-box cardinfo cardnumber'}
                            name='cardNumber'
                            placeholder='Card Number'
                            value={localCardData.cardNumber}
                            {...getCardNumberProps({
                                onChange: handleCardDataChange,
                            })}
                        />
                        {errors?.cardNumber && (
                            <span role='alert' className='input-error'>
                                {errors.cardNumber}
                            </span>
                        )}
                    </div>
                    <div className='inline-inputs'>
                        <div className='input-group card-code'>
                            <label htmlFor='cvc' hidden>
                                Card Code
                            </label>
                            <input
                                aria-invalid={errors.cvc ? 'true' : 'false'}
                                className={errors.cvc ? 'input-box cardinfo cardcode is-invalid' : 'input-box cardinfo cardcode'}
                                name='cvc'
                                placeholder='Card Code'
                                value={localCardData.cvc}
                                {...getCVCProps({
                                    onChange: handleCardDataChange,
                                })}
                            />
                            {errors?.cvc && (
                                <span role='alert' className='input-error'>
                                    {errors.cvc}
                                </span>
                            )}
                        </div>
                        <div className='input-group expiry-date'>
                            <label htmlFor='expiryDate' hidden>
                                Expiry Date
                            </label>
                            <input
                                aria-invalid={errors.expiryDate ? 'true' : 'false'}
                                className={errors.expiryDate ? 'input-box cardinfo expirydate is-invalid' : 'input-box cardinfo expirydate'}
                                name='expiryDate'
                                placeholder='Exp date: MM/YYYY'
                                value={localCardData.expiryDate}
                                {...getExpiryDateProps({
                                    onChange: (event) => {
                                        const dateItems = event.target.value.split(' / ');
                                        setLocalCardData((prevData) => ({
                                            ...prevData,
                                            expiryDate: event.target.value,
                                            expMonth: dateItems[0],
                                            expYear: dateItems[1],
                                        }));
                                        removeError(event.target.name);
                                    },
                                })}
                            />
                            {errors?.expiryDate && (
                                <span role='alert' className='input-error'>
                                    {errors.expiryDate}
                                </span>
                            )}
                        </div>
                        <div className='input-group zip-code'>
                            <label htmlFor='zipCode' hidden>
                                Zip Code
                            </label>
                            <input
                                aria-invalid={errors.zip ? 'true' : 'false'}
                                className={errors.zip ? 'input-box cardinfo is-invalid' : 'input-box cardinfo'}
                                name='zip'
                                placeholder='Zip Code'
                                value={localCardData.zip}
                                {...getZIPProps({
                                    onChange: handleCardDataChange,
                                })}
                            />
                            {errors?.zip && (
                                <span role='alert' className='input-error'>
                                    {errors.zip}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className='action-buttons'>
                    <Button
                        className='outlined-button'
                        action={
                            loader
                                ? () => {}
                                : () => {
                                      moveStep(-1);
                                  }
                        }
                        text='Back'
                        icon={1}
                    />
                    <Button
                        className='red-button'
                        type='button'
                        action={loader ? () => {} : handleFormSubmit}
                        text={loader ? '' : 'Request Service'}
                        icon={loader ? 0 : 2}
                        customIcon={loader ? <ScaleLoader color='white' /> : null}
                        disabled={loader || error}
                    />
                </div>
            </form>
        </>
    );
};

export default PaymentForm;
