import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getCookieData } from '../../utils/cookies';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

export function handleApiErrors(response, setError) {
    if (!response?.data) {
        return;
    }
    const data = response.data;

    const snakeToCamel = (str) => str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

    const routeError = () => {
        if (data.type === 'validation_error') {
            let errorMsgs = handleValidationError();
            if (errorMsgs) {
                return errorMsgs;
            }
        } else if (data.type === 'client_error') {
            return handleClientError();
        }
        toast.error('Something went wrong. Please try again.');
    };
    const handleValidationError = () => {
        data.errors.forEach((error) => {
            setError(error.attr, { type: 'custom', message: error.detail });
        });
    };
    const handleClientError = () => {
        data.errors.forEach((error) => {
            toast.error(error.detail);
        });
    };
    const process = () => {
        try {
            return routeError();
        } catch (e) {
            console.log(e);
        }
        toast.error('Something went wrong. Please try again.');
    };

    return process();
}

export const useApi = (access, refresh, logout, formElem = null) => {
    let backendDomain;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        backendDomain = 'http://0.0.0.0:8000';
    } else {
        backendDomain = '';
    }
    const BASE_URL = `${backendDomain}/api`;
    const [loading, setLoading] = useState(false);

    const getHeaders = (auth = false, file = false) => {
        let headers = {};
        headers['X-CSRF-Token'] = getCookieData()?.csrftoken;
        if (auth) {
            headers['Authorization'] = `Bearer ${access}`;
        }
        if (file) {
            headers['Content-Type'] = 'multipart/form-data';
        } else {
            headers['Content-Type'] = 'application/json';
        }
        return headers;
    };

    const formatDate = (value) => {
        // convert to YYYY-MM-DD format required by API
        let dd = value.getDate();
        let mm = value.getMonth() + 1;
        let yyyy = value.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    };

    const reformatData = (value) => {
        if (value instanceof Date) {
            return formatDate(value);
        }
        return value;
    };

    const formatBody = (obj) => {
        if (typeof obj != 'object') return obj;

        for (let oldName in obj) {
            // Camel to underscore
            let newName = oldName.replace(/([A-Z])/g, function ($1) {
                return '_' + $1.toLowerCase();
            });

            // Only process if names are different
            if (newName !== oldName) {
                // Check for the old property name to avoid a ReferenceError in strict mode.
                if (obj.hasOwnProperty(oldName)) {
                    obj[newName] = reformatData(obj[oldName]);
                    delete obj[oldName];
                }
            }

            // Recursion
            if (typeof obj[newName] == 'object') {
                obj[newName] = formatBody(obj[newName]);
            }
        }
        return obj;
    };

    const get = async ({ url, headers = null, handleErrors = true, setError }) => {
        if (!headers) {
            headers = getHeaders();
        }
        setLoading(true);
        let isSuccess = false;
        const resp = await axios
            .get(BASE_URL + url, { headers: headers })
            .then((response) => {
                isSuccess = true;
                return response;
            })
            .catch((error) => {
                if (handleErrors) {
                    return handleApiErrors(error.response, formElem);
                }
                return error.response;
            });
        setLoading(false);
        return {
            response: resp,
            success: isSuccess,
        };
    };

    const post = async ({ url, body, headers = null, handleErrors = true, setError }) => {
        if (!headers) {
            headers = getHeaders();
        }
        setLoading(true);
        let isSuccess = false;
        body = formatBody(body);
        const resp = await axios
            .post(BASE_URL + url, body, { headers: headers })
            .then((response) => {
                isSuccess = true;
                return response;
            })
            .catch((error) => {
                if (handleErrors) {
                    return handleApiErrors(error.response, setError);
                }
                return error.response;
            });
        setLoading(false);
        return {
            response: resp,
            success: isSuccess,
        };
    };

    const put = async ({ url, body, headers = null, handleErrors = true, setError }) => {
        if (!headers) {
            headers = getHeaders();
        }
        let isSuccess = false;
        body = formatBody(body);
        const resp = await axios
            .put(BASE_URL + url, body, { headers: headers })
            .then((response) => {
                isSuccess = true;
                return response;
            })
            .catch((error) => {
                if (handleErrors) {
                    return handleApiErrors(error.response, setError);
                }
                return error.response;
            });
        return {
            response: resp,
            success: isSuccess,
        };
    };

    const patch = async ({ url, body, headers = null, handleErrors = true, setError }) => {
        if (!headers) {
            headers = getHeaders();
        }
        let isSuccess = false;
        body = formatBody(body);
        const resp = await axios
            .patch(BASE_URL + url, body, { headers: headers })
            .then((response) => {
                isSuccess = true;
                return response;
            })
            .catch((error) => {
                if (handleErrors) {
                    return handleApiErrors(error.response, setError);
                }
                return error.response;
            });
        return {
            response: resp,
            success: isSuccess,
        };
    };

    const authGet = async ({ url, headers = null, handleErrors = false, setError }) => {
        if (!headers) {
            headers = getHeaders(true);
        }
        // Get a list of objects or a single object from the API
        let resp = await get({
            url: url,
            headers: headers,
            handleErrors: handleErrors,
            setError: setError,
        })
            .then((response) => {
                return response;
            })
            .catch((response) => {
                return response;
            });
        if (resp.status === 401) {
            // Try to refresh token and retry API call
            refresh();
            resp = await authGet({
                url: url,
                headers: headers,
                retry: true,
            });
        }
        return resp;
    };

    const authPost = async ({ url, body, file = false, headers = null, handleErrors = false, setError }) => {
        if (!headers) {
            headers = getHeaders(true, file);
        }
        // Create an object through the API
        let resp = await post({
            url: url,
            body: body,
            headers: headers,
            handleErrors: handleErrors,
            setError: setError,
        })
            .then((response) => {
                return response;
            })
            .catch((response) => {
                return response;
            });
        if (resp.response.status === 401) {
            // Try to refresh token and retry API call
            refresh();
            resp = await authPost({
                url: url,
                body: body,
                headers: headers,
                retry: true,
            });
        }
        return resp;
    };

    const authPut = async ({ url, body, file = false, headers = null, retry = false, setError }) => {
        if (!headers) {
            headers = getHeaders(true, file);
        }
        // Create an object through the API
        let resp = await put({
            url: url,
            body: body,
            headers: headers,
            setError: setError,
        })
            .then((response) => {
                return response;
            })
            .catch((response) => {
                return response;
            });
        if (resp.response.status === 401) {
            // Try to refresh token and retry API call
            refresh();
            resp = await authPut({
                url: url,
                body: body,
                headers: headers,
                retry: true,
            });
        }
        return resp;
    };

    const authPatch = async ({ url, body, file = false, headers = null, retry = false, setError }) => {
        if (!headers) {
            headers = getHeaders(true, file);
        }
        // Create an object through the API
        let resp = await patch({
            url: url,
            body: body,
            headers: headers,
            setError: setError,
        })
            .then((response) => {
                return response;
            })
            .catch((response) => {
                return response;
            });
        if (resp.response.status === 401) {
            // Try to refresh token and retry API call
            refresh();
            resp = await authPatch({
                url: url,
                body: body,
                headers: headers,
                retry: true,
            });
        }
        return resp;
    };

    const authDelete = async (url, headers = null, retry = false, setError) => {
        if (!headers) {
            headers = getHeaders(true);
        }
        // Delete an object through the API
        setLoading(true);
        let isSuccess = false;
        let resp = await axios
            .delete(BASE_URL + url, { headers: headers })
            .then((response) => {
                isSuccess = true;
                return response;
            })
            .catch((error) => {
                isSuccess = false;
                return error.response;
            });
        if (resp.status === 401 && !retry) {
            // Try to refresh token and retry API call
            refresh();
            resp = await authDelete({
                url: url,
                headers: headers,
                retry: true,
            });
        }
        setLoading(false);
        return {
            response: resp,
            success: isSuccess,
        };
    };

    return { get, post, put, patch, authGet, authPost, authPut, authPatch, authDelete, loading };
};
