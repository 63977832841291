
import {ReactComponent as RightArrow } from "../../../assets/svg/rightarrow.svg";
import {ReactComponent as LeftArrow } from "../../../assets/svg/leftarrow.svg";

export default function Button({className, action, text, icon, customIcon, customIconRight, type = "button"}){

    return(

        <button className={className} onClick={action} type={type}>
            {icon === 1 && <LeftArrow className="icon"/>}
            {customIcon}
            {text}
            {icon === 2 && <RightArrow className="icon"/>}
            {customIconRight}
        </button>
    )
}