import { useState } from 'react';

//PASSWORD STRENGTH METER

export function getPasswordStrength(password) {
    let strength = 0;
  
    if (password.length > 7) {
        strength += 1;
    }
  
    if (password.match(/[a-z]+/)) {
        strength += 1;
    }
  
    if (password.match(/[A-Z]+/)) {
        strength += 1;
    }
  
    if (password.match(/[0-9]+/)) {
        strength += 1;
    }
  
    if (password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
        strength += 1;
    }
  
    return {
        height: '5px',
        borderRadius: '3px',
        backgroundColor: '#ddd',
        position: 'relative',
        backgroundImage: 'linear-gradient(270deg, #6BEF3D -11.45%, #EFDD3D 46.02%, #EF3D3D 100%)',
        backgroundSize: '100% 100%',
        width: `${strength * 20}%`,
    };
}


//PASSWORD VALIDATOR & API


export function SendPass({password}) {
    const [hasLength, setHasLength] = useState(true);
    const [hasUpperCase, setHasUpperCase] = useState(true);
    const [hasLowerCase, setHasLowerCase] = useState(true);
    const [hasDigit, setHasDigit] = useState(true);
    const [hasSymbol, setHasSymbol] = useState(true);

    setHasLength(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasDigit(/[0-9]/.test(password));
    setHasSymbol(/[!@#$%^&*(),.?":{}|<>]/.test(password));

    if (hasLength && hasUpperCase && hasLowerCase && hasDigit && hasSymbol) {
        //API CALL
    }
    else{
        //CALL ALERT
    }

}

export const FormatPhoneNumber = (phoneNumber) => {
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    const truncatedNumber = digitsOnly.slice(0, 10);
    const formattedNumber = truncatedNumber
        .replace(/^(\d{1,3})/, '($1')
        .replace(/^(\(\d{3})(\d{1,3})/, '$1) $2')
        .replace(/^(\(\d{3}\) \d{1,3})(\d{1,4})/, '$1-$2');
    return {
        formattedNumber: formattedNumber, 
        digitsOnly: truncatedNumber
    };
};

export const validateEmailAddress = (emailAddress) => {
    const errors = {};
    if(!emailAddress) {
        errors.email = "Please provide an email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
        errors.email = "Please provide a valid email address";
    }
    return errors;
}