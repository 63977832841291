import './login.scss';
import { useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { toast } from 'react-toastify';
import { useApi } from '../../hooks/api';
import PasswordBox from '../../components/includes/passwordBox';
import Button from '../../components/includes/button';
// import ReCAPTCHA from 'react-google-recaptcha';
import ScaleLoader from 'react-spinners/ScaleLoader';

const Login = () => {
    const { login, access, refresh, logout, requestPasswordReset, recaptchaKey } = useAuth();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();
    const api = useApi(access, refresh, logout);
    const navigate = useNavigate();
    //sign in or sign up
    const [signIn, setSignIn] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [sent, setSent] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    // const [recaptcha, setRecaptcha] = useState(false);
    const [loading, setLoading] = useState(false);

    // const handleRecaptchaChange = (value) => {
    //     setRecaptcha(!!value);
    // };

    const handleLogin = async (data) => {
        // if (!recaptcha) {
        //     toast.error('Please verify that you are not a robot');
        //     return;
        // }
        setLoading(true);
        await login(data, rememberMe, setError)
            .then((resp) => {
                setLoading(false);
                if (resp === 'success') {
                    navigate('/dashboard');
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.message);
            });
        setLoading(false);
    };

    const handleForgotPassword = async (data) => {
        // if (!recaptcha) {
        //     toast.error('Please verify that you are not a robot');
        //     return;
        // }
        setLoading(true);
        await requestPasswordReset(data, setError)
            .then((resp) => {
                if (resp) {
                    setLoading(false);
                    navigate('/');
                    toast.success('We sent you an email with instructions to reset your password.');
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.message);
            });
    };

    return (
        <div className='page-container'>
            <div className='login-box light-shadow'>
                {signIn ? (
                    <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleLogin)}>
                        <div className='top-text' style={{ textAlign: 'center', paddingBlock: '1rem' }}>
                            <h1 style={{ marginBottom: '.5rem' }}>Sign In to your Service Provider Account</h1>
                        </div>
                        <div className='sign-in'>
                            <div>
                                <input
                                    {...register('email', { required: 'Please enter your email address' })}
                                    aria-invalid={errors.email ? 'true' : 'false'}
                                    type='email'
                                    placeholder='Email Address'
                                    className={errors.email ? 'input-box is-invalid' : 'input-box'}
                                />
                                {errors.email && (
                                    <span role='alert' className='input-error'>
                                        {errors.email?.message}
                                    </span>
                                )}
                            </div>
                            <PasswordBox register={register} errors={errors} setValue={setValue} placeholder='Password' />
                            {/* eslint-disable-next-line */}
                            <a onClick={() => setSignIn(false)}>Forgot Password</a>
                            <label>
                                <input type='checkbox' checked={rememberMe} name='rememberMe' onChange={(e) => setRememberMe(e.target.checked)} />
                                Remember me
                            </label>
                            {/* <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                                <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaChange} />
                            </div> */}
                            <div style={{ marginInline: 'auto' }}>
                                <Button
                                    type='submit'
                                    className='red-button'
                                    text={loading ? '' : 'SIGN IN'}
                                    customIcon={loading ? <ScaleLoader color='white' height='30px' /> : null}
                                />
                            </div>
                        </div>
                    </form>
                ) : !sent ? (
                    <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleForgotPassword)}>
                        <div className='top-text' style={{ textAlign: 'center', paddingBlock: '1rem' }}>
                            <h1 style={{ marginBottom: '.5rem' }}>Forgot Password</h1>
                            <p>Recover Your Account</p>
                        </div>
                        <div className='sign-in'>
                            <p>Enter your email address and we will send you a link to reset your password</p>
                            <div>
                                <input
                                    {...register('email', { required: 'Please enter your email address' })}
                                    aria-invalid={errors.email ? 'true' : 'false'}
                                    type='email'
                                    placeholder='Email Address'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={errors.email ? 'input-box is-invalid' : 'input-box'}
                                />
                                {errors.email && (
                                    <span role='alert' className='input-error'>
                                        {errors.email?.message}
                                    </span>
                                )}
                            </div>
                            {/* eslint-disable-next-line */}
                            <a onClick={() => setSignIn(true)}>Sign In</a>
                            {/* <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                                <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaChange} />
                            </div> */}
                            <div style={{ marginInline: 'auto' }}>
                                <Button
                                    className='red-button'
                                    text={loading ? '' : 'Send Email'}
                                    type='submit'
                                    customIcon={loading ? <ScaleLoader color='white' height='30px' /> : null}
                                />
                            </div>
                        </div>
                    </form>
                ) : (
                    <>
                        <div className='top-text' style={{ textAlign: 'center', paddingBlock: '1rem' }}>
                            <h1 style={{ marginBottom: '.5rem' }}>Forgot Password</h1>
                            <p>Recover Your Account</p>
                        </div>
                        <div className='sign-in'>
                            <p>Email Sent Successfuly</p>
                            {/* eslint-disable-next-line */}
                            <a onClick={() => setSent(true)}>Back</a>
                            {/* <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                                <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaChange} />
                            </div> */}
                            <div style={{ marginInline: 'auto' }}>
                                <Button className='red-button' text='Back To Home' action={() => navigate('/')} />
                                <Button
                                    className='outline-button'
                                    text={loading ? '' : 'Resend Email'}
                                    action={loading ? null : handleForgotPassword}
                                    customIcon={loading ? <ScaleLoader color='white' height='30px' /> : null}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Login;
