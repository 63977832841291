import './index.scss';

import TowManImg from '../../assets/img/truck_driver_01.jpg';
import TextExample01 from '../../assets/img/textExample_01.png';

// import { ReactComponent as Business } from '../../assets/svg/aboutBusiness.svg';
// import { ReactComponent as Fuel } from '../../assets/svg/aboutFuel.svg';
// import { ReactComponent as Lock } from '../../assets/svg/aboutLock.svg';
// import { ReactComponent as Tow } from '../../assets/svg/aboutTow.svg';

import { useNavigate } from 'react-router-dom';

export default function TowComp() {
    const navigate = useNavigate();

    return (
        <div className='provider-page'>
            <div className='our-mission'>
                <div className='title'>
                    <h1 className='greeting'>We Want You!</h1>
                </div>
                <div className='hero-wrapper'>
                    <div className='about-text-our-mission'>
                        <h1>{'\u2022'} You set the prices</h1>
                        <h1>{'\u2022'} You get paid weekly</h1>
                        <p>
                            Roadside service providers are an essential part of any community. Make your business run smoother, faster, and easier by
                            using Textatow!
                        </p>
                        <button
                            className='register-button outlined-button action-button'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/signup');
                            }}
                        >
                            SIGN UP
                        </button>
                    </div>
                    <img src={TowManImg} alt='' style={{ maxWidth: '375px', borderRadius: '10%' }} />
                </div>
            </div>

            <div className='our-vision'>
                <div className='title'>
                    <h1>How It Works</h1>
                    <p>You sign up, We send you jobs.</p>
                </div>
                {/* <div className='vision-board'>
                    <div className='vision-item'>
                        <Tow />
                        <h3>Service Network</h3>
                        <p>
                            Become an{' '}
                            <span>
                                <u>Independent</u>
                            </span>{' '}
                            part of a network of service providers. Allowing you to be seen by more people and get more business.
                        </p>
                    </div>
                    <div className='vision-item'>
                        <Business />
                        <h3>Complete Control</h3>
                        <p>
                            Have complete control over <strong>Your</strong> business. You set your own prices, and you decide when we send you
                            customers.
                        </p>
                    </div>
                    <div className='vision-item'>
                        <Lock />
                        <h3>Secure Pay</h3>
                        <p>We want you to get paid! We use a secure payment system to ensure that you get paid for your services.</p>
                    </div>
                    <div className='vision-item'>
                        <Fuel />
                        <h3>Fast and Efficient</h3>
                        <p>
                            You and your customers will connect <strong>Fast</strong>. We use geolocation and a simple text message to send business
                            your way.
                        </p>
                    </div>
                </div> */}
            </div>

            <div className='green-statement'>
                <div className='green-wrapper'>
                    <div className='green-item-wrapper'>
                        <div className='green-item one'>
                            <h1>Example</h1>
                            <p>
                                After creating a business profile, you'll be able to
                                <br />
                                <br />
                                <list>
                                    <li>Set your schedule</li>
                                    <li>Set your services/pricing</li>
                                    <li>Receive service requests.</li>
                                </list>
                            </p>

                            <img className='texts' src={TextExample01} alt='Text Example 1' />
                            <p>It's as easy as that! Sign up and start receiving customers!</p>
                            <button
                                className='register-button outlined-button action-button'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/signup');
                                }}
                            >
                                SIGN UP
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
