import React from 'react';
import { AuthProvider } from './auth';
import { SchemaProvider } from './schema';


const AppProviders = ({ children }) => {
    return (
        <>
            <AuthProvider>
                <SchemaProvider>
                    {children}
                </SchemaProvider>
            </AuthProvider>
        </>
    )
};

export { AppProviders };