import { useState } from "react";

export default function TimeSelector({from, to, onChange}) {

    const handleTimeChange = (e, fieldName) => {
        const updatedTimeSlot = { from, to, [fieldName]: e.target.value };
        onChange(updatedTimeSlot);
    };

    

    return (
        <>
            From
            <input
                type="time"
                className="time-input"
                value={from}
                onChange={(e) => handleTimeChange(e, "from")}
            />
            To
            <input
                type="time"
                className="time-input"
                value={to}
                onChange={(e) => handleTimeChange(e, "to")}
            />
        </>
    );
}