import { ReactComponent as Battery } from '../../../assets/svg/battery.svg';
import { ReactComponent as RightArrow } from '../../../assets/svg/rightarrow.svg';
import Button from '../../includes/button';

import { useState, useEffect } from 'react';
import { FeeTable, FeeItem } from '../feeTable';
import TopServiceBar from '../topServiceBar';
import { OverviewChart, OverviewChartItem } from '../overviewChart';
import { useForm } from 'react-hook-form';
import ScaleLoader from 'react-spinners/ScaleLoader';

const serviceType = 1;

export function BatteryService({ isActive, setStep }) {
    return (
        <div className={isActive ? 'service-item active-service-item' : 'service-item'} onClick={() => setStep(serviceType)}>
            <Battery /> Battery
        </div>
    );
}

export function BatteryPricing({ service, updateServiceFee, handleFormSubmit, toggleServiceStatus, loading }) {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const [batteryActive, setBatteryActive] = useState(false);
    const [dispatchFee, setDispatchFee] = useState(0);
    const [fuelSurcharge, setFuelSurcharge] = useState(0);
    const [inRouteCharge, setInRouteCharge] = useState(0);
    const [replacementFee, setReplacementFee] = useState(0);

    const [totalMiles, setTotalMiles] = useState(15);
    const [totalFees, setTotalFees] = useState(0);
    const [totalCostPerMile, setTotalCostPerMile] = useState(0);

    const handleFeeChange = (name, value) => {
        let tempValue = parseFloat(value);
        switch (name) {
            case '1':
                setDispatchFee(tempValue);
                break;
            case '2':
                setFuelSurcharge(tempValue);
                break;
            case '3':
                setInRouteCharge(tempValue);
                break;
            case '6':
                setReplacementFee(tempValue);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        let total = Number(dispatchFee) + Number(replacementFee) + Number(fuelSurcharge) + Number(inRouteCharge) * Number(totalMiles);
        total = total.toFixed(2);

        let mileCost = total / totalMiles;
        mileCost = mileCost.toFixed(2);

        setTotalFees(total);
        setTotalCostPerMile(mileCost);
    }, [dispatchFee, fuelSurcharge, inRouteCharge, replacementFee]);

    useEffect(() => {
        if (service?.active) {
            setBatteryActive(service.active);
        }
    }, [service]);

    const handleBatteryActive = () => {
        toggleServiceStatus({ val: !batteryActive, setError: setError });
        setBatteryActive((prevState) => !prevState);
    };

    return batteryActive ? (
        <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit((data) => handleFormSubmit({ data: data, setError: setError }))}>
            <TopServiceBar handleActive={handleBatteryActive} currentState={batteryActive} />
            <p>Set your pricing.</p>
            <input {...register('active')} type='hidden' value={batteryActive} />
            <FeeTable>
                <FeeItem
                    label='Dispatch Fee'
                    name='1'
                    service={service}
                    updateServiceFee={updateServiceFee}
                    register={register}
                    errors={errors}
                    onFeeChange={handleFeeChange}
                />
                <FeeItem
                    label='Fuel Surcharge'
                    name='2'
                    service={service}
                    updateServiceFee={updateServiceFee}
                    register={register}
                    errors={errors}
                    onFeeChange={handleFeeChange}
                />
                <FeeItem
                    label='In Route Charge'
                    name='3'
                    service={service}
                    updateServiceFee={updateServiceFee}
                    register={register}
                    errors={errors}
                    onFeeChange={handleFeeChange}
                />
                <FeeItem
                    label='Jump Fee'
                    name='6'
                    service={service}
                    updateServiceFee={updateServiceFee}
                    register={register}
                    errors={errors}
                    onFeeChange={handleFeeChange}
                />
            </FeeTable>

            <OverviewChart>
                <OverviewChartItem name='Total Miles Traveled' price={totalMiles} last={false} />
                <OverviewChartItem name="Total Fee's" price={`\$${totalFees}`} last={false} />
                <OverviewChartItem name='Total Cost Per Mile' price={`\$${totalCostPerMile}`} last={true} />
            </OverviewChart>

            <Button text={loading ? '' : 'Save'} className='red-button' type='submit' customIcon={loading ? <ScaleLoader color='white' /> : null} />
        </form>
    ) : (
        <>
            <TopServiceBar handleActive={handleBatteryActive} currentState={batteryActive} />
            <p>Service Inactive</p>
        </>
    );
}
