import './terms.scss';
import React from 'react';

const Terms = () => {
    return (
        <div className='terms-container'>
            <div id='outputPage' className='ContractText'>
                <div className='format-html'>
                    <div data-exp='simple2' className='outputVersion1'>
                        <div className=' header'>
                            <span className='content'>Website Terms and Conditions</span>
                            <span className='pageNumbers'>
                                Page <span className='currentPageNum'></span> of <span className='totalPageNum'></span>
                            </span>
                        </div>
                        <div className=' footer'></div>
                        <div className=' firstHeader'></div>
                        <div className=' firstFooter'>
                            <span className='pageNumbers'>
                                Page <span className='currentPageNum'></span> of <span className='totalPageNum'></span>
                            </span>
                        </div>
                        <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'center' }}>
                            <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>TERMS AND CONDITIONS</span>
                        </p>
                        <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                            These terms and conditions (the "Terms and Conditions") govern the use of <span style={{ fontStyle: 'normal', fontWeight: 'bold' }}>textatow.com</span> (the "Site"). This
                            Site is owned and operated by Christopher Lee Wright. This Site is an ecommerce website.<br></br>
                            <br></br>By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.<br></br>
                            <br></br>THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT CAREFULLY.
                        </p>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Intellectual Property</span>
                                <br></br>All content published and made available on our Site is the property of Christopher Lee Wright. This includes, but is not limited to images, text, logos,
                                documents, downloadable files and anything that contributes to the composition of our Site.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Accounts</span>
                                <br></br>When you create an account on our Site, you agree to the following:
                            </p>
                            <ol start='1' style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', listStyle: 'decimal' }}>
                                <li style={{ marginBottom: 18 }} value='1'>
                                    <span>
                                        You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account;
                                        and
                                    </span>
                                    <span style={{ color: 'black' }}>
                                        <br></br>
                                    </span>
                                </li>
                                <li style={{ marginBottom: 0 }} value='2'>
                                    <span>
                                        All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it
                                        changes.
                                    </span>
                                    <span style={{ color: 'black' }}>
                                        <br></br>
                                    </span>
                                </li>
                            </ol>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Sale of Services</span>
                                <br></br>These Terms and Conditions govern the sale of services available on our Site.
                            </p>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>The following services are available on our Site:</p>
                            <ul style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', listStyle: 'disc' }}>
                                <li style={{ marginBottom: 0 }} value='1'>
                                    <span>Connecting Users to Roadside Service Providers.</span>
                                    <span style={{ color: 'black' }}>
                                        <br></br>
                                    </span>
                                </li>
                            </ul>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>The services will be paid for in full when the services are ordered.</p>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                These Terms and Conditions apply to all the services that are displayed on our Site at the time you access it. All information, descriptions, or images that we provide
                                about our services are as accurate as possible. However, we are not legally bound by such information, descriptions, or images as we cannot guarantee the accuracy of
                                all services we provide. You agree to purchase services from our Site at your own risk.
                            </p>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel your order and have already processed your payment, we will give
                                you a refund equal to the amount you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt of any refund.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Third Party Goods and Services</span>
                                <br></br>Our Site may offer goods and services from third parties. We cannot guarantee the quality or accuracy of goods and services made available by third parties on
                                our Site.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Payments</span>
                                <br></br>We accept the following payment methods on our Site:
                            </p>
                            <ul style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', listStyle: 'disc' }}>
                                <li style={{ marginBottom: 18 }} value='1'>
                                    <span>Credit Card;</span>
                                    <span style={{ color: 'black' }}>
                                        <br></br>
                                    </span>
                                </li>
                                <li style={{ marginBottom: 18 }} value='2'>
                                    <span>PayPal; and</span>
                                    <span style={{ color: 'black' }}>
                                        <br></br>
                                    </span>
                                </li>
                                <li style={{ marginBottom: 0 }} value='3'>
                                    <span>Debit.</span>
                                    <span style={{ color: 'black' }}>
                                        <br></br>
                                    </span>
                                </li>
                            </ul>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                When you provide us with your payment information, you authorize our use of and access to the payment instrument you have chosen to use. By providing us with your
                                payment information, you authorize us to charge the amount due to this payment instrument.<br></br>
                                <br></br>If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.
                            </p>
                        </div>
                        <div>
                            <div>
                                <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                    <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Refunds</span>
                                    <br></br>
                                    <span style={{ textDecoration: 'underline' }}>Refunds for Services</span>
                                    <br></br>We provide refunds for services sold on our Site as follows:
                                </p>
                                <ul style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', listStyle: 'disc' }}>
                                    <li style={{ marginBottom: 0 }} value='1'>
                                        <span>No refunds.</span>
                                        <span style={{ color: 'black' }}>
                                            <br></br>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Consumer Protection Law</span>
                                <br></br>Where any consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal rights and
                                remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these
                                Terms and Conditions and that legislation, the mandatory provisions of the legislation will apply.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Links to Other Websites</span>
                                <span style={{ textDecoration: 'underline' }}>
                                    <br></br>
                                </span>
                                Our Site contains links to third party websites or services that we do not own or control. We are not responsible for the content, policies, or practices of any third
                                party website or service linked to on our Site. It is your responsibility to read the terms and conditions and privacy policies of these third party websites before
                                using these sites.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Limitation of Liability</span>
                                <br></br>Christopher Lee Wright and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses,
                                damages, liabilities and expenses including legal fees from your use of the Site.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Indemnity</span>
                                <br></br>Except where prohibited by law, by using this Site you indemnify and hold harmless Christopher Lee Wright and our directors, officers, agents, employees,
                                subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your
                                violation of these Terms and Conditions.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Applicable Law</span>
                                <br></br>These Terms and Conditions are governed by the laws of the State of Arizona.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Dispute Resolution</span>
                                <br></br>Subject to any exceptions specified in these Terms and Conditions, if you and Christopher Lee Wright are unable to resolve any dispute through informal
                                discussion, then you and Christopher Lee Wright agree to submit the issue first before a non-binding mediator and to an arbitrator in the event that mediation fails.
                                The decision of the arbitrator will be final and binding. Any mediator or arbitrator must be a neutral party acceptable to both you and Christopher Lee Wright. The
                                costs of any mediation or arbitration will be shared equally between you and Christopher Lee Wright.
                            </p>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                Notwithstanding any other provision in these Terms and Conditions, you and Christopher Lee Wright agree that you both retain the right to bring an action in small
                                claims court and to bring an action for injunctive relief or intellectual property infringement.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Severability</span>
                                <br></br>If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions
                                will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and
                                Conditions will still be considered valid.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Changes</span>
                                <br></br>These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our
                                Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.
                            </p>
                        </div>
                        <div>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>
                                <span style={{ fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'underline' }}>Contact Details</span>
                                <br></br>Please contact us if you have any questions or concerns. Our contact details are as follows:<br></br>
                                <br></br>(720) 393-0660<br></br>chris.bhome@gmail.com<br></br>21030 E Desert Hills Circle
                            </p>
                            <p style={{ fontSize: 12, fontFamily: 'Times New Roman', color: 'black', textAlign: 'left' }}>You can also contact us through the feedback form available on our Site.</p>
                        </div>
                        <p style={{ fontSize: 12, lineHeight: 18, fontFamily: 'Times New Roman', color: 'black', textAlign: 'right' }}>Effective Date: 1st day of November, 2023</p>
                        <div className='LDCopyright'>
                            <p>&copy;2002-2023 LawDepot.com&reg;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terms;
