import './footer.scss';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import stateArray from '../../../pages/statesOfService/stateFlags';

export default function Footer() {
    const [showStates, setShowStates] = useState(false);
    const states = stateArray;

    return (
        <footer className='footer'>
            <div className='footer-links'>
                <p>@ 2023 - Copyright TextATow</p>
                <div className='footer-links-right'>
                    <Link to={'terms/'}>Terms</Link>
                    <Link to={'privacy/'}>Privacy</Link>
                </div>
            </div>

            <div className='footer-links-2'>
                <p onClick={() => setShowStates(!showStates)}>States with service {showStates ? 'v' : '>'} </p>
                <div className='footer-links-right'>
                    {showStates &&
                        states.map((state) => (
                            <Link key={state.abbreviation} to={`/${state.name.replace(/\s/g, '')}-${state.abbreviation}`} onClick={() => {
                                setShowStates(!showStates);
                                }}>
                                {state.name}
                            </Link>
                        ))}
                </div>
            </div>
        </footer>
    );
}
