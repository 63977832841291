import { Link } from "react-router-dom";

const E404 = () => {
    return (
        <div className="page-container" style={{flexDirection:"column", display:"flex", textAlign:"center", margin:"10px auto", alignContent:"flex-start"}}>
            <h1 style={{color:"red", fontSize:"5rem"}}>404</h1>
            <h2>Page not found</h2>
            <p>Sorry, the page you are looking for does not exist.</p>
            <div style={{marginTop:"2rem"}}>
                <Link to="/" style={{padding:".5rem 1.5rem", border:"solid 1px #0093d0", textDecoration:"none", color:"#fff", backgroundColor:"#0093d0"}}>Go back to the homepage</Link>

            </div >
        </div>

    );
    }

export default E404;