import { useState } from 'react';
import {ReactComponent as AboutDown} from '../../assets/svg/aboutDown.svg'
import {ReactComponent as AboutUp} from '../../assets/svg/aboutUp.svg'

export default function CultureItem ({title, text}){

    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    }

    return (
        <div className="culture-item" onClick={toggleShow}>
            <div className="toggle-bar" >
                <h1>{title}</h1>
                <AboutDown className={show ? "hidden" : ""} />
                <AboutUp className={show ? "" : "hidden"} />
            </div>
            <p className={show ? "" : "hidden"}>{text}</p>
        </div>
    )
}