import { useEffect, useState, createContext, useContext } from 'react';
import { useApi } from '../../hooks/api';
import { toast } from 'react-toastify';
import { csrfToken } from '../../utils/cookies';

export const AuthContext = createContext({loading: true});

export const AuthProvider = ({ children }) => {
    const api = useApi();
    const [access, setAccess] = useState(JSON.parse(localStorage.getItem('access')));
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const recaptchaKeyDev = '6LdulRYnAAAAAIg4zDIwSj68xPEP_v0h2JceY2se';
    const recaptchaKeyProd = '6LeDvP8oAAAAAKZ2wZeokNZssqrGv7OqAPJmgbYX';
    const recaptchaKey = process.env.NODE_ENV === 'development' ? recaptchaKeyDev : recaptchaKeyProd;

    const refreshToken = async () => {
        const resp = await api
            .post({
                url: '/token/refresh/',
                body: { refresh: localStorage.getItem('token') },
            })
            .then((results) => {
                if (resp.status === 'success') {
                    localStorage.setItem('access', JSON.stringify(results.response.data.access));
                    localStorage.setItem('token', JSON.stringify(results.response.data.refresh));
                    setAccess(results.response.data.access);
                } else {
                    toast.error('Please login again.');
                }
            });
    };

    useEffect(() => {
        const remeberMe = JSON.parse(localStorage.getItem('remember'));
        if (remeberMe && !remeberMe?.remember && remeberMe?.exp < Date.now()) {
            localStorage.removeItem('remember');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            setAccess(null);
            setUser(null);
        }
    }, []);

    useEffect(() => {
        const setCurrentUser = async () => {
            await api
                .get({
                    url: '/users/me/',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + access,
                        'X-CSRF-Token': csrfToken,
                    },
                })
                .then((results) => {
                    if (results.success) {
                        setUser(results.response.data);
                    }
                    setLoading(false);
                });
        };
        if (access) {
            setCurrentUser();
        } else {
            setLoading(false);
        }
    }, [access]);

    const refreshUserData = () => {
        refreshToken();
    };

    const login = async (body, remember, setError) => {
        const resp = await api
            .post({
                url: '/token/',
                body: body,
                setError: setError,
            })
            .then((results) => {
                if (results.success) {
                    const data = results.response.data;
                    localStorage.setItem('access', JSON.stringify(data.access));
                    localStorage.setItem('remember', JSON.stringify({ remember: remember, exp: Date.now() + 3600000 }));
                    localStorage.setItem('token', JSON.stringify(data.refresh));
                    setAccess(data.access);
                    return 'success';
                } else {
                    return results.response;
                }
            });
        return resp;
    };

    const logout = () => {
        localStorage.removeItem('access');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('remember');
        setUser(null);
        setAccess(null);
    };

    const requestPasswordReset = async (body, setError) => {
        return await api
            .post({
                url: '/password-reset/',
                body: body,
                setError: setError,
            })
            .then((response) => {
                return 'success';
            })
            .catch((response) => {
                return 'error';
            });
    };

    const is_authenticated = () => {
        return access !== null;
    };

    const value = {
        user,
        access,
        login,
        logout,
        refreshToken,
        requestPasswordReset,
        is_authenticated,
        refreshUserData,
        recaptchaKey,
        loading,
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};


export const useAuth = () =>  useContext(AuthContext);