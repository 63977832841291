import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './serviceType.scss';
import { ReactComponent as Battery } from '../../assets/svg/battery.svg';
import { ReactComponent as FlatTire } from '../../assets/svg/flat.svg';
import { ReactComponent as Fuel } from '../../assets/svg/fuel.svg';
import { ReactComponent as Lockout } from '../../assets/svg/locked.svg';
import { ReactComponent as Stuck } from '../../assets/svg/stuck.svg';
import { ReactComponent as Tow } from '../../assets/svg/tow.svg';
import Button from '../includes/button';

export default function ServiceType({ userType }) {
    const navigate = useNavigate();
    const [serviceType, setServiceType] = useState(0);

    const changeServiceType = (e) => {
        setServiceType(e);
    };

    const handleContinue = () => {
        if (userType) {
            navigate('/location', { state: { serviceType } });
            return;
        }
        navigate('/');
        return;
    };

    return (
        <div className='service-selector'>
            <div className='top'>
                <h1 className='headline1 service-header'>
                    Request Your Roadside Service
                    <p>Without Needing A Phone Call</p>
                </h1>
            </div>

            <div className='service-type-buttons'>
                <Button
                    className={serviceType === 0 ? 'outlined-button-red' : 'outlined-button'}
                    action={() => changeServiceType(0)}
                    text='Tow'
                    customIcon={<Tow />}
                />
                <Button
                    className={serviceType === 1 ? 'outlined-button-red' : 'outlined-button'}
                    action={() => changeServiceType(1)}
                    text='Battery'
                    customIcon={<Battery />}
                />
                <Button
                    className={serviceType === 2 ? 'outlined-button-red' : 'outlined-button'}
                    action={() => changeServiceType(2)}
                    text='Flat Tire'
                    customIcon={<FlatTire />}
                />
                <Button
                    className={serviceType === 3 ? 'outlined-button-red' : 'outlined-button'}
                    action={() => changeServiceType(3)}
                    text='Fuel'
                    customIcon={<Fuel />}
                />
                <Button
                    className={serviceType === 4 ? 'outlined-button-red' : 'outlined-button'}
                    action={() => changeServiceType(4)}
                    text='Lockout'
                    customIcon={<Lockout />}
                />
                <Button
                    className={serviceType === 5 ? 'outlined-button-red' : 'outlined-button'}
                    action={() => changeServiceType(5)}
                    text='Stuck'
                    customIcon={<Stuck />}
                />
            </div>

            <Button className='red-button continue-button' text='CONTINUE' action={handleContinue} icon={2} />
        </div>
    );
}
