import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as MapPin } from '../../assets/svg/mappin.svg';
import { ReactComponent as Phone } from '../../assets/svg/contactPhone.svg';
import { ReactComponent as ContactMail } from '../../assets/svg/contactmail.svg';
import Button from '../../components/includes/button';
import './contact.scss';
import { useForm } from 'react-hook-form';
import { useApi } from '../../hooks/api';
import { useAuth } from '../../contexts/auth';

export default function Contact() {
    const {
        register,
        reset,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const { access, refresh, logout } = useAuth();
    const api = useApi(access, refresh, logout);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const submitContactUsForm = async (data) => {
        if (!data.consent) {
            setError('consent', { type: 'custom', message: 'You must accept to continue' });
            // setLoading(false);
            return;
        }
        await api
            .post({
                url: `/contact-us-form/`,
                body: data,
                setError: setError,
            })
            .then((results) => {
                if (results.success) {
                    reset({});
                    toast.success("Thanks for your message. We'll get in touch with you soon.");
                }
            });
    };

    return (
        <div className='page-container'>
            <div className='contact-page light-shadow'>
                <div className='contact-page-info'>
                    <h4>Contact Us</h4>
                    <h1>Get In Touch</h1>
                    <p className='subheader-text'>
                        Any of the listed contact methods will connect YOU directly to US! Don’t hesitate to reach out with any questions, concerns or
                        suggestions.
                    </p>
                    <div className='contact-item'>
                        <MapPin />
                        <div className='contact-item-right'>
                            <h3>Our Location</h3>
                            <p>Phoenix, AZ</p>
                        </div>
                    </div>
                    <div className='contact-item'>
                        <Phone />
                        <div className='contact-item-right'>
                            <h3>Phone Number</h3>
                            <p>(623) 305-2656</p>
                        </div>
                    </div>
                    <div className='contact-item'>
                        <ContactMail />
                        <div className='contact-item-right'>
                            <h3>Email</h3>
                            <p>customerservice@textatow.org</p>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(submitContactUsForm)} className='contact-form'>
                    <input
                        type='text'
                        className={errors.name ? 'input-box is-invalid' : 'input-box'}
                        placeholder='Name (required)'
                        name='name'
                        {...register('name', { required: 'This is required' })}
                        aria-invalid={errors.name ? 'true' : 'false'}
                    />
                    {errors.name && (
                        <span role='alert' className='input-error'>
                            {errors.name?.message}
                        </span>
                    )}
                    <input
                        type='email'
                        className={errors.email ? 'input-box is-invalid' : 'input-box'}
                        placeholder='Email (required)'
                        name='email'
                        {...register('email', { required: 'This is required' })}
                        aria-invalid={errors.email ? 'true' : 'false'}
                    />
                    {errors.email && (
                        <span role='alert' className='input-error'>
                            {errors.email?.message}
                        </span>
                    )}
                    <input
                        type='text'
                        className={errors.phone ? 'input-box is-invalid' : 'input-box'}
                        placeholder='Phone (required)'
                        name='phone'
                        {...register('phone', { required: 'This is required' })}
                        aria-invalid={errors.phone ? 'true' : 'false'}
                    />
                    {errors.phone && (
                        <span role='alert' className='input-error'>
                            {errors.phone?.message}
                        </span>
                    )}
                    <textarea
                        className={errors.message ? 'input-box is-invalid' : 'input-box'}
                        placeholder='Your Message (required)'
                        name='message'
                        {...register('message', { required: 'This is required' })}
                        aria-invalid={errors.message ? 'true' : 'false'}
                    />
                    {errors.message && (
                        <span role='alert' className='input-error'>
                            {errors.message?.message}
                        </span>
                    )}
                    <div>
                        <input
                            {...register('consent', { required: 'You must accept to continue.' })}
                            type='checkbox'
                            id='smsConsent'
                            name='smsConsent'
                        />
                        <label htmlFor='smsConsent'>
                            I consent to receiving communications from Textatow. Message & data rates may apply. Reply CANCEL to cancel
                            communications.
                        </label>
                    </div>
                    {errors.consent && (
                        <span role='alert' className='input-error'>
                            {errors.consent?.message}
                        </span>
                    )}
                    <Button className='red-button' icon={2} text='SEND' type='submit' />
                </form>
            </div>
        </div>
    );
}
