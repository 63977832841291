

export const getCookieData = () => {
    const cookieString = document.cookie;
    const cookieArray = cookieString
    .split(";")
    .map((cookie) => cookie.trim().split("="));
    let cookieData = {};
    cookieArray.forEach((cookie) => {
        const key = cookie[0];
        const value = cookie[1];
        cookieData[key] = value;
    });
    return cookieData;
};

export const csrfToken = getCookieData()?.csrftoken;