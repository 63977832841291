import { useState, useEffect } from 'react';
import SignupCompany from '../signupCompany';
import { useAuth } from '../../contexts/auth';
import { useApi } from '../../hooks/api';
import PriceManagement from '../priceManagement';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';

export default function Dashboard({ authContext }) {
    const { user, access, refresh, logout } = useAuth();
    const api = useApi(access, refresh, logout);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [provider, setProvider] = useState({
        name: null,
        reviewStatus: 0,
        schedule: {},
        zipCode: null,
        zipCoverage: [],
        primaryPhone: {
            number: null,
        },
        phones: [],
        primaryEmail: {
            email: null,
        },
        emails: [],
        address: {
            line_1: null,
            line_2: null,
            city: null,
            state: null,
            zipCode: null,
        },
        federalTax: {
            type: null,
            taxId: null,
        },
        preferredPayment: {
            payByCheck: null,
            address: {
                line_1: null,
                line_2: null,
                city: null,
                state: null,
                zipCode: null,
            },
            accountNumber: null,
            routingNumber: null,
            proof_of_deposit: null,
        },
    });

    const getUserProviders = async () => {
        await api
            .authGet({
                url: `/providers/?owner__uuid=${user.uuid}`,
            })
            .then((results) => {
                if (results.success) {
                    if (results.response.data.results.length > 0) {
                        setProvider(results.response.data.results[0]);
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (user && !user?.emailAddress?.isVerified) {
            // navigate user to email confirmation page if email isn't confirmed yet
            if (user.emailAddress?.uuid) {
                navigate(`/confirm-email/?token=${user.emailAddress.uuid}`);
            } else {
                navigate('/confirm-email/');
            }
        }
        if (user) {
            getUserProviders();
        }
    }, [user]);

    const handleProviderChange = (data, nestedKey) => {
        setLoading(true);
        if (nestedKey) {
            setProvider((prevData) => ({
                ...prevData,
                [nestedKey]: data,
            }));
        } else {
            setProvider(data);
        }
        setLoading(false);
    };

    return (
        // if loading is true, show loading spinner
        loading ? (
            <ScaleLoader color='#1cc5b7' />
        ) : // if provider is null, show signup form
        provider.reviewStatus === 1 ? (
            <PriceManagement provider={provider} setProvider={handleProviderChange} authContext={authContext} updatePage={getUserProviders} />
        ) : (
            // once the provider finishes the signup process, they will be redirected to the price management page
            <SignupCompany provider={provider} setProvider={handleProviderChange} authContext={authContext} />
        )
    );
}
