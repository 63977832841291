import { useState } from 'react';
import { toast } from 'react-toastify';

const useAddress = () => {
    const [address, setAddress] = useState({
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
        lat: '',
        lng: '',
    });

    const [newAddress, setNewAddress] = useState({
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
        lat: '',
        lng: '',
    });

    const handleAddressChange = (data, keyName, callback = () => {}) => {
        if (keyName) {
            setAddress((prevState) => {
                const newState = { ...prevState, [keyName]: data };
                callback(newState);
                return newState;
            });
        } else if (!data) {
            setAddress({
                line1: '',
                line2: '',
                city: '',
                state: '',
                zipCode: '',
                lat: '',
                lng: '',
            });
        } else {
            setAddress(data);
            callback(data);
        }
    };

    const validateAddress = async (address) => {
        return new Promise((resolve, reject) => {
            if (address.line1 === '') {
                reject(new Error('Address is required'));
            }
            if (address.city === '') {
                reject(new Error('City is required'));
            }
            if (address.state === '') {
                reject(new Error('State is required'));
            }
            if (address.zipCode === '') {
                reject(new Error('Zip code is required'));
            }

            const geocoder = new window.google.maps.Geocoder();
            const addressString = address.line1 + ' ' + address.city + ' ' + address.state + ' ' + address.zipCode;
            geocoder.geocode({ address: addressString }, (results, status) => {
                if (status === 'OK') {
                    if (results[0] && results[0].address_components.find((component) => component.types.includes('country')).short_name === 'US') {
                        resolve(true);
                    }
                    reject(new Error('You must be in the United States to use this service'));
                } else {
                    reject(new Error('Unable to validate address'));
                }
            });
        });
    };

    const getCurrentLocation = async () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const geocoder = new window.google.maps.Geocoder();
                        const latlng = {
                            lat: parseFloat(position.coords.latitude.toFixed(6)),
                            lng: parseFloat(position.coords.longitude.toFixed(6)),
                        };
                        newAddress.lat = latlng.lat;
                        newAddress.lng = latlng.lng;
                        geocoder.geocode({ location: latlng }, (results, status) => {
                            if (status === 'OK') {
                                if (results[0]) {
                                    if (results[0].address_components.find((component) => component.types.includes('country')).short_name !== 'US') {
                                        toast.error('You must be in the United States to use this service');
                                        reject(new Error('You must be in the United States to use this service'));
                                    }
                                    results[0].address_components.forEach((component, index) => {
                                        if (['establishment', 'point_of_interest', 'transit_station'].some((r) => component.types.includes(r))) {
                                            newAddress.line1 = component.long_name;
                                        }
                                        if (component.types.includes('street_number')) {
                                            newAddress.line1 = component.long_name;
                                        }
                                        if (component.types.includes('route')) {
                                            newAddress.line1 += ' ' + component.long_name;
                                        }
                                        if (component.types.includes('subpremise')) {
                                            newAddress.line2 = component.long_name;
                                        }
                                        if (component.types.includes('locality')) {
                                            newAddress.city = component.long_name;
                                        }
                                        if (component.types.includes('administrative_area_level_1')) {
                                            newAddress.state = component.short_name;
                                        }
                                        if (component.types.includes('postal_code')) {
                                            newAddress.zipCode = component.long_name;
                                        }
                                    });

                                    address.line1 = newAddress.line1;
                                    address.line2 = newAddress.line2;
                                    address.city = newAddress.city;
                                    address.state = newAddress.state;
                                    address.zipCode = newAddress.zipCode;
                                    address.lat = newAddress.lat;
                                    address.lng = newAddress.lng;

                                    resolve(true);
                                } else {
                                    toast.error('No results found');
                                    reject(new Error('No results found'));
                                }
                            }
                        });
                    },
                    (error) => {
                        console.error(error);
                        reject(new Error('Unable to retrieve location'));
                    },
                    { enableHighAccuracy: true }
                );
            } else {
                toast.error('Geolocation is not supported by this browser.');
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
    };

    return { address, handleAddressChange, validateAddress, getCurrentLocation };
};

export default useAddress;
