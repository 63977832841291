import ALFlag from '../../assets/state_flags/Flag_of_Alabama.svg';
import AKFlag from '../../assets/state_flags/Flag_of_Alaska.svg';
import AZFlag from '../../assets/state_flags/Flag_of_Arizona.svg';
import ARFlag from '../../assets/state_flags/Flag_of_Arkansas.svg';
import CAFlag from '../../assets/state_flags/Flag_of_California.svg';
import COFlag from '../../assets/state_flags/Flag_of_Colorado.svg';
import CTFlag from '../../assets/state_flags/Flag_of_Connecticut.svg';
import DCFlag from '../../assets/state_flags/Flag_of_the_District_of_Columbia.svg';
import DEFlag from '../../assets/state_flags/Flag_of_Delaware.svg';
import FLFlag from '../../assets/state_flags/Flag_of_Florida.svg';
import GAFlag from '../../assets/state_flags/Flag_of_Georgia.svg';
import HIFlag from '../../assets/state_flags/Flag_of_Hawaii.svg';
import IDFlag from '../../assets/state_flags/Flag_of_Idaho.svg';
import ILFlag from '../../assets/state_flags/Flag_of_Illinois.svg';
import INFlag from '../../assets/state_flags/Flag_of_Indiana.svg';
import IAFlag from '../../assets/state_flags/Flag_of_Iowa.svg';
import KSFlag from '../../assets/state_flags/Flag_of_Kansas.svg';
import KYFlag from '../../assets/state_flags/Flag_of_Kentucky.png';
import LAFlag from '../../assets/state_flags/Flag_of_Louisiana.svg';
import MEFlag from '../../assets/state_flags/Flag_of_Maine.svg';
import MDFlag from '../../assets/state_flags/Flag_of_Maryland.svg';
import MAFlag from '../../assets/state_flags/Flag_of_Massachusetts.png';
import MIFlag from '../../assets/state_flags/Flag_of_Michigan.svg';
import MNFlag from '../../assets/state_flags/Flag_of_Minnesota.svg';
import MSFlag from '../../assets/state_flags/Flag_of_Mississippi.svg';
import MOFlag from '../../assets/state_flags/Flag_of_Missouri.png';
import MTFlag from '../../assets/state_flags/Flag_of_Montana.svg';
import NEFlag from '../../assets/state_flags/Flag_of_Nebraska.svg';
import NVFlag from '../../assets/state_flags/Flag_of_Nevada.svg';
import NHFlag from '../../assets/state_flags/Flag_of_New_Hampshire.svg';
import NJFlag from '../../assets/state_flags/Flag_of_New_Jersey.png';
import NMFlag from '../../assets/state_flags/Flag_of_New_Mexico.svg';
import NYFlag from '../../assets/state_flags/Flag_of_New_York.svg';
import NCFlag from '../../assets/state_flags/Flag_of_North_Carolina.svg';
import NDFlag from '../../assets/state_flags/Flag_of_North_Dakota.svg';
import OHFlag from '../../assets/state_flags/Flag_of_Ohio.svg';
import OKFlag from '../../assets/state_flags/Flag_of_Oklahoma.svg';
import ORFlag from '../../assets/state_flags/Flag_of_Oregon.svg';
import PAFlag from '../../assets/state_flags/Flag_of_Pennsylvania.svg';
import RIFlag from '../../assets/state_flags/Flag_of_Rhode_Island.svg';
import SCFlag from '../../assets/state_flags/Flag_of_South_Carolina.svg';
import SDFlag from '../../assets/state_flags/Flag_of_South_Dakota.svg';
import TNFlag from '../../assets/state_flags/Flag_of_Tennessee.svg';
import TXFlag from '../../assets/state_flags/Flag_of_Texas.svg';
import UTFlag from '../../assets/state_flags/Flag_of_Utah.svg';
import VTFlag from '../../assets/state_flags/Flag_of_Vermont.svg';
import VAFlag from '../../assets/state_flags/Flag_of_Virginia.png';
import WAFlag from '../../assets/state_flags/Flag_of_Washington.svg';
import WVFlag from '../../assets/state_flags/Flag_of_West_Virginia.svg';
import WIFlag from '../../assets/state_flags/Flag_of_Wisconsin.svg';
import WYFlag from '../../assets/state_flags/Flag_of_Wyoming.png';

export const stateFlags = {
    AL: ALFlag,
    AK: AKFlag,
    AZ: AZFlag,
    AR: ARFlag,
    CA: CAFlag,
    CO: COFlag,
    CT: CTFlag,
    DC: DCFlag,
    DE: DEFlag,
    FL: FLFlag,
    GA: GAFlag,
    HI: HIFlag,
    ID: IDFlag,
    IL: ILFlag,
    IN: INFlag,
    IA: IAFlag,
    KS: KSFlag,
    KY: KYFlag,
    LA: LAFlag,
    ME: MEFlag,
    MD: MDFlag,
    MA: MAFlag,
    MI: MIFlag,
    MN: MNFlag,
    MS: MSFlag,
    MO: MOFlag,
    MT: MTFlag,
    NE: NEFlag,
    NV: NVFlag,
    NH: NHFlag,
    NJ: NJFlag,
    NM: NMFlag,
    NY: NYFlag,
    NC: NCFlag,
    ND: NDFlag,
    OH: OHFlag,
    OK: OKFlag,
    OR: ORFlag,
    PA: PAFlag,
    RI: RIFlag,
    SC: SCFlag,
    SD: SDFlag,
    TN: TNFlag,
    TX: TXFlag,
    UT: UTFlag,
    VT: VTFlag,
    VA: VAFlag,
    WA: WAFlag,
    WV: WVFlag,
    WI: WIFlag,
    WY: WYFlag,
};

const fullStateNameList = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};

const states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'District of Columbia', abbreviation: 'DC' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
];

export function getStateFlag(stateName) {
    return stateFlags[stateName];
}

export function getAbbreviatedStateName(stateAbbreviation) {
    return Object.keys(fullStateNameList).find((key) => fullStateNameList[key] === stateAbbreviation);
}

export default states; // export default states;
