import { ReactComponent as Hide } from '../../../assets/svg/hide.svg';
import { useState } from 'react';
import './passwordBox.scss';

const PasswordBox = ({ register, errors, fieldName = 'password', placeholder }) => {
    //password visibility
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <div className='input-wrapper'>
                <input
                    className={errors?.hasOwnProperty(fieldName) ? 'input-box is-invalid' : 'input-box'}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={placeholder ? placeholder : 'password'}
                    {...register(fieldName, { required: 'Please enter your password' })}
                    aria-invalid={errors?.hasOwnProperty(fieldName) ? 'true' : 'false'}
                />
                <Hide className='icon' onClick={toggleShowPassword} />
            </div>
            {errors?.hasOwnProperty(fieldName) && (
                <span role='alert' className='input-error'>
                    {errors[fieldName]?.message}
                </span>
            )}
        </div>
    );
};

export default PasswordBox;
