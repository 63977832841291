import ToggleSwitch from "../../toggle";

export default function TopServiceBar({handleActive, currentState}){

    return(
        <div className="top-service-bar">
            <h3>Fee Prices</h3>
            <div className="toggle-item"><ToggleSwitch onToggle={handleActive} currentState={currentState}/></div>
        </div>
    );
}