import React, { useState, useEffect } from 'react';
import Button from '../includes/button';
import { useForm } from 'react-hook-form';

const maxCarYear = new Date().getFullYear() + 2;

export default function CarInfo({ moveStep, order, handleOrderChange }) {
    const [vehicle, setVehicle] = useState({
        uuid: order?.vehicle.uuid,
        customer: order?.vehicle.customer.uuid,
        color: order?.vehicle?.color || '',
        year: order?.vehicle?.year || '',
        make: order?.vehicle?.make || '',
        model: order?.vehicle?.model || '',
        description: order?.vehicle?.description || '',
    });
    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            color: vehicle.color,
            year: vehicle.year,
            make: vehicle.make,
            model: vehicle.model,
            description: vehicle.description,
        },
    });

    useEffect(() => {
        reset(vehicle);
    }, [vehicle]);

    const handleFormSubmit = (data) => {
        handleOrderChange({
            data: { vehicle: { ...data, uuid: vehicle.uuid, customer: vehicle.customer } },
            setError: setError,
        });
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <h2>Tell us more about your car.</h2>
            <div className='car-details'>
                <div className='car-details-inputs'>
                    <div className='input-group'>
                        <input
                            {...register('color', {
                                required: 'This is required',
                                maxLength: {
                                    value: 254,
                                    message: 'Must be less than 255 characters',
                                },
                            })}
                            aria-invalid={errors.color ? 'true' : 'false'}
                            type='text'
                            className={errors.color ? 'input-box is-invalid' : 'input-box'}
                            name='color'
                            placeholder='Color'
                        />
                        {errors.color && (
                            <span role='alert' className='input-error'>
                                {errors.color?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <input
                            {...register('year', {
                                required: 'This is required',
                                min: {
                                    value: 1908,
                                    message: 'Must be after 1908',
                                },
                                max: {
                                    value: maxCarYear,
                                    message: `Must be before ${maxCarYear}`,
                                },
                                maxLength: 4,
                            })}
                            aria-invalid={errors.year ? 'true' : 'false'}
                            type='number'
                            className={errors.year ? 'input-box is-invalid' : 'input-box'}
                            name='year'
                            placeholder='Year'
                        />
                        {errors.year && (
                            <span role='alert' className='input-error'>
                                {errors.year?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <input
                            {...register('make', {
                                required: 'This is required',
                                maxLength: {
                                    value: 254,
                                    message: 'Must be less than 255 characters',
                                },
                            })}
                            aria-invalid={errors.make ? 'true' : 'false'}
                            type='text'
                            className={errors.make ? 'input-box is-invalid' : 'input-box'}
                            name='make'
                            placeholder='Make'
                        />
                        {errors.make && (
                            <span role='alert' className='input-error'>
                                {errors.make?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <input
                            {...register('model', {
                                required: 'This is required',
                                maxLength: {
                                    value: 254,
                                    message: 'Must be less than 255 characters long',
                                },
                            })}
                            aria-invalid={errors.model ? 'true' : 'false'}
                            type='text'
                            className={errors.model ? 'input-box is-invalid' : 'input-box'}
                            name='model'
                            placeholder='Model'
                        />
                        {errors.model && (
                            <span role='alert' className='input-error'>
                                {errors.model?.message}
                            </span>
                        )}
                    </div>
                </div>
                <textarea
                    {...register('description', {
                        maxLength: {
                            value: 200,
                            message: 'Must be less than 200 characters long',
                        },
                    })}
                    aria-invalid={errors.description ? 'true' : 'false'}
                    name='description'
                    placeholder='Other Details (Optional)'
                    cols='30'
                    rows='10'
                    className={errors.description ? 'input-box is-invalid' : 'input-box'}
                />
                <div id='charCount' style={vehicle.description?.length > 200 ? { color: 'red' } : {}}>
                    {vehicle?.description?.length || 0}/200
                </div>
                {errors.description && (
                    <span role='alert' className='input-error'>
                        {errors.description?.message}
                    </span>
                )}
            </div>

            <div className='action-buttons'>
                <Button className='outlined-button' action={() => moveStep(-1)} text='Back' icon={1} />
                <Button className='red-button' text='Next' icon={2} type='submit' />
            </div>
        </form>
    );
}
