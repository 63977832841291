import React from 'react';


export const defaultSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Textatow",
    "url": "https://textatow.com/",
    "logo": "https://textatow.com/static/media/mobileLogo.7e319b9326bb2ef4ea01.png",
    "image": "https://textatow.com/static/media/mobileLogo.7e319b9326bb2ef4ea01.png",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "21030 Desert Hills Circle",
      "addressLocality": "Queen Creek",
      "addressRegion": "AZ",
      "postalCode": "85142",
      "addressCountry": "US"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-623-305-2656",
      "contactType": "Customer Service",
      "availableLanguage": "English"
    },
    "areaServed": {
      "@type": "Place",
      "name": "Mesa, AZ"
    },
    "description": "Textatow provides 24/7 towing and roadside assistance services in Arizona.",
    "telephone": "+1-623-305-2656",
    "priceRange": "$$",
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "serviceType": "Roadside Assistance",
    "servicePage": "https://textatow.com/roadside-assistance/",
    "category": "Towing Service"
}

export const mesaAzSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Textatow",
    "url": "https://textatow.com/",
    "logo": "https://textatow.com/static/media/mobileLogo.7e319b9326bb2ef4ea01.png",
    "image": "https://textatow.com/static/media/mobileLogo.7e319b9326bb2ef4ea01.png",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "21030 Desert Hills Circle",
      "addressLocality": "Queen Creek",
      "addressRegion": "AZ",
      "postalCode": "85142",
      "addressCountry": "US"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-623-305-2656",
      "contactType": "Customer Service",
      "availableLanguage": "English"
    },
    "areaServed": {
      "@type": "Place",
      "name": "Mesa, AZ"
    },
    "description": "Textatow provides 24/7 towing and roadside assistance services in Mesa, Arizona.",
    "telephone": "+1-623-305-2656",
    "priceRange": "$$",
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "serviceType": "Roadside Assistance",
    "servicePage": "https://textatow.com/mesa-roadside-assistance/",
    "category": "Towing Service"
}

export const queenCreekAzSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Textatow",
    "url": "https://textatow.com/",
    "logo": "https://textatow.com/static/media/mobileLogo.7e319b9326bb2ef4ea01.png",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "21030 Desert Hills Circle",
      "addressLocality": "Queen Creek",
      "addressRegion": "AZ",
      "postalCode": "85142",
      "addressCountry": "US"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-623-305-2656",
      "contactType": "Customer Service",
      "availableLanguage": "English"
    },
    "areaServed": {
      "@type": "Place",
      "name": "Queen Creek, AZ"
    },
    "description": "Textatow offers 24/7 towing and roadside assistance services in Queen Creek, Arizona.",
    "telephone": "+1-623-305-2656",
    "priceRange": "$$",
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "serviceType": "Roadside Assistance in Queen Creek",
    "servicePage": "https://textatow.com/queen-creek-roadside-assistance/",
    "category": "Towing Service"
}

export const gilbertAzSchema = {
    "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Textatow",
  "url": "https://textatow.com/",
  "logo": "https://textatow.com/static/media/mobileLogo.7e319b9326bb2ef4ea01.png",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "21030 Desert Hills Circle",
    "addressLocality": "Queen Creek",
    "addressRegion": "AZ",
    "postalCode": "85142",
    "addressCountry": "US"
  },
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+1-623-305-2656",
    "contactType": "Customer Service",
    "availableLanguage": "English"
  },
  "areaServed": {
    "@type": "Place",
    "name": "Gilbert, AZ"
  },
  "description": "Textatow offers 24/7 towing and roadside assistance services in Gilbert, Arizona.",
  "telephone": "+1-623-305-2656",
  "priceRange": "$$",
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  },
  "serviceType": "Roadside Assistance in Gilbert, AZ",
  "servicePage": "https://textatow.com/gilbert-roadside-assistance/",
  "category": "Towing Service"
}

export const chandlerAzSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Textatow",
    "url": "https://textatow.com/",
    "logo": "https://textatow.com/static/media/mobileLogo.7e319b9326bb2ef4ea01.png",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "21030 Desert Hills Circle",
      "addressLocality": "Queen Creek",
      "addressRegion": "AZ",
      "postalCode": "85142",
      "addressCountry": "US"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-623-305-2656",
      "contactType": "Customer Service",
      "availableLanguage": "English"
    },
    "areaServed": {
      "@type": "Place",
      "name": "Chandler, AZ"
    },
    "description": "Textatow offers 24/7 towing and roadside assistance services in Chandler, Arizona.",
    "telephone": "+1-623-305-2656",
    "priceRange": "$$",
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "serviceType": "Roadside Assistance in Chandler, AZ",
    "servicePage": "https://textatow.com/chandler-roadside-assistance/",
    "category": "Towing Service"
}

export const bigMetaSchema = {
    defaultSchema,
    mesaAzSchema,
    queenCreekAzSchema,
    gilbertAzSchema,
    chandlerAzSchema
}

// todo: create array of strings to map through for each route associated with a schema