import { ReactComponent as Stuck } from '../../../assets/svg/stuck.svg';
import Button from '../../includes/button';

import { useState, useEffect } from 'react';
import { FeeTable, FeeItem } from '../feeTable';
import TopServiceBar from '../topServiceBar';
import { OverviewChart, OverviewChartItem } from '../overviewChart';
import { useForm } from 'react-hook-form';
import ScaleLoader from 'react-spinners/ScaleLoader';

const serviceType = 5;

export function StuckService({ isActive, setStep }) {
    return (
        <div className={isActive ? 'service-item active-service-item' : 'service-item'} onClick={() => setStep(serviceType)}>
            <Stuck /> Stuck
        </div>
    );
}

export function StuckPricing({ service, updateServiceFee, handleFormSubmit, toggleServiceStatus, loading }) {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const [stuckActive, setStuckActive] = useState(false);
    const [dispatchFee, setDispatchFee] = useState(0);
    const [fuelSurcharge, setFuelSurcharge] = useState(0);
    const [inRouteCharge, setInRouteCharge] = useState(0);
    const [winchFee, setWinchFee] = useState(0);

    const [totalMiles, setTotalMiles] = useState(15);
    const [totalFees, setTotalFees] = useState(0);
    const [totalCostPerMile, setTotalCostPerMile] = useState(0);

    const handleFeeChange = (name, value) => {
        switch (name) {
            case '1':
                setDispatchFee(value);
                break;
            case '2':
                setFuelSurcharge(value);
                break;
            case '3':
                setInRouteCharge(value);
                break;
            case '9':
                setWinchFee(value);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        let total = Number(dispatchFee) + Number(winchFee) + Number(fuelSurcharge) + Number(inRouteCharge) * Number(totalMiles);
        total = total.toFixed(2);

        let mileCost = total / totalMiles;
        mileCost = mileCost.toFixed(2);

        setTotalFees(total);
        setTotalCostPerMile(mileCost);
    }, [dispatchFee, fuelSurcharge, inRouteCharge, winchFee]);

    useEffect(() => {
        if (service?.active) {
            setStuckActive(service.active);
        }
    }, [service]);

    const handleStuckActive = () => {
        toggleServiceStatus({ val: !stuckActive, setError: setError });
        setStuckActive((prevState) => !prevState);
    };

    return stuckActive ? (
        <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit((data) => handleFormSubmit({ data: data, setError: setError }))}>
            <TopServiceBar handleActive={handleStuckActive} currentState={stuckActive} />
            <p>Please adjust these prices for your service as you desire.</p>
            <input {...register('active')} type='hidden' value={stuckActive} />
            <FeeTable>
                <FeeItem label='Dispatch Fee' name='1' service={service} updateServiceFee={updateServiceFee} register={register} errors={errors} onFeeChange={handleFeeChange} />
                <FeeItem label='Fuel Surcharge' name='2' service={service} updateServiceFee={updateServiceFee} register={register} errors={errors} onFeeChange={handleFeeChange} />
                <FeeItem label='In Route Charge' name='3' service={service} updateServiceFee={updateServiceFee} register={register} errors={errors} onFeeChange={handleFeeChange} />
                <FeeItem label='Winch Fee' name='9' service={service} updateServiceFee={updateServiceFee} register={register} errors={errors} onFeeChange={handleFeeChange} />
            </FeeTable>

            <OverviewChart>
                <OverviewChartItem name='Total Miles Traveled' price={totalMiles} last={false} />
                <OverviewChartItem name="Total Fee's" price={`\$${totalFees}`} last={false} />
                <OverviewChartItem name='Total Cost Per Mile' price={`\$${totalCostPerMile}`} last={true} />
            </OverviewChart>

            <Button text={loading ? '' : 'Save'} className='red-button' type='submit' customIcon={loading ? <ScaleLoader color='white' /> : null} />
        </form>
    ) : (
        <>
            <TopServiceBar handleActive={handleStuckActive} currentState={stuckActive} />
            <p>Service Inactive</p>
        </>
    );
}
