import { ReactComponent as Tow } from '../../../assets/svg/tow.svg';
import ScaleLoader from 'react-spinners/ScaleLoader';

import { useState, useEffect } from 'react';
import { FeeTable, FeeItem } from '../feeTable';
import TopServiceBar from '../topServiceBar';
import { OverviewChart, OverviewChartItem } from '../overviewChart';
import { useForm } from 'react-hook-form';
import Button from '../../includes/button';

const serviceType = 0;

export function TowService({ isActive, setStep }) {
    return (
        <div className={isActive ? 'service-item active-service-item' : 'service-item'} onClick={() => setStep(serviceType)}>
            <Tow /> Tow
        </div>
    );
}

export function TowPricing({ service, updateServiceFee, handleFormSubmit, toggleServiceStatus, loading }) {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const [towActive, setTowActive] = useState(false);
    const [dispatchFee, setDispatchFee] = useState(0);
    const [hookupFee, setHookupFee] = useState(0);
    const [inRouteCharge, setInRouteCharge] = useState(0);
    const [towingFee, setTowingFee] = useState(0);

    const [totalMiles, setTotalMiles] = useState(15);
    const [totalFees, setTotalFees] = useState(0);
    const [totalCostPerMile, setTotalCostPerMile] = useState(0);

    const handleFeeChange = (name, value) => {
        let tempValue = parseFloat(value);
        switch (name) {
            case '1':
                setDispatchFee(tempValue);
                break;
            case '4':
                setHookupFee(tempValue);
                break;
            case '3':
                setInRouteCharge(tempValue);
                break;
            case '5':
                setTowingFee(tempValue);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        let total = Number(dispatchFee) + Number(hookupFee) + (Number(inRouteCharge) * Number(totalMiles)) / 2 + (Number(towingFee) * Number(totalMiles)) / 2;
        total = total.toFixed(2);

        let mileCost = total / totalMiles;
        mileCost = mileCost.toFixed(2);

        setTotalFees(total);
        setTotalCostPerMile(mileCost);
    }, [dispatchFee, hookupFee, inRouteCharge, towingFee]);

    useEffect(() => {
        if (service?.active) {
            setTowActive(service.active);
        }
    }, [service]);

    const handleTowActive = () => {
        toggleServiceStatus({ val: !towActive, setError: setError });
        setTowActive((prevState) => !prevState);
    };

    return towActive ? (
        <form
            onSubmit={
                loading
                    ? (e) => e.preventDefault()
                    : handleSubmit((data) => {
                          handleFormSubmit({ data: data, setError: setError });
                      })
            }
        >
            <TopServiceBar handleActive={handleTowActive} currentState={towActive} />
            <p>Please adjust these prices for your service as you desire.</p>
            <input {...register('active')} type='hidden' value={towActive} />
            <FeeTable>
                <FeeItem label='Dispatch Fee' name='1' service={service} updateServiceFee={updateServiceFee} register={register} errors={errors} onFeeChange={handleFeeChange} />
                <FeeItem label='Hookup Fee' name='4' service={service} updateServiceFee={updateServiceFee} register={register} errors={errors} onFeeChange={handleFeeChange} />
                <FeeItem label='In Route Charge' name='3' service={service} updateServiceFee={updateServiceFee} register={register} errors={errors} onFeeChange={handleFeeChange} />
                <FeeItem label='Towing Charge' name='5' service={service} updateServiceFee={updateServiceFee} register={register} errors={errors} onFeeChange={handleFeeChange} />
            </FeeTable>

            <OverviewChart>
                <OverviewChartItem name='Total Miles Traveled' price={totalMiles} last={false} />
                <OverviewChartItem name="Total Fee's" price={`\$${totalFees}`} last={false} />
                <OverviewChartItem name='Total Cost Per Mile' price={`\$${totalCostPerMile}`} last={true} />
            </OverviewChart>

            <Button text={loading ? '' : 'Save'} className='red-button' type='submit' customIcon={loading ? <ScaleLoader color='white' /> : null} />
        </form>
    ) : (
        <>
            <TopServiceBar handleActive={handleTowActive} currentState={towActive} />
            <p>Service Inactive</p>
        </>
    );
}
