import React, { useState } from 'react';
import { ReactComponent as MenuIcon } from '../../../assets/svg/mobilemenu.svg';
import { ReactComponent as MenuX } from '../../../assets/svg/menux.svg';

import './mobileMenu.scss';

export default function MobileMenu({ links, handleNavigate }) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className='mobile-menu'>
            {menuOpen ? <MenuX onClick={toggleMenu} /> : <MenuIcon onClick={toggleMenu} />}

            <div className={`menu-links ${menuOpen ? 'open' : ''}`}>
                <ul>
                    {links.map((link, index) => (
                        <li
                            key={index}
                            className={`${link.active ? 'active' : ''} mobile-menu-button`}
                            onClick={() => {
                                toggleMenu();
                                handleNavigate(link.path);
                            }}
                        >
                            {link.title}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
