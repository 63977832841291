import './location.scss';
import Button from '../includes/button';
import { ReactComponent as CarLocation } from '../../assets/svg/carlocation.svg';
import useAddress from '../../hooks/address';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import stateArray from '../../pages/statesOfService/stateFlags';

// toast to confirm address, loading module for button, base url settings var for fetch requests

const LocationForm = ({ moveStep, step, text, option, order, handleOrderChange }) => {
    const stateList = stateArray;
    const [loading, setLoading] = useState(false);
    const [locating, setLocating] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { address, handleAddressChange, validateAddress, getCurrentLocation } = useAddress();

    useEffect(() => {
        if (option === 'from' && order.pickup.address.line1) {
            handleAddressChange(order.pickup.address);
        } else if (order?.dropOff?.address) {
            handleAddressChange(order?.dropOff?.address);
        }
    }, [order]);

    const {
        register,
        handleSubmit,
        getValues,
        watch,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            line1: address.line1,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
        },
    });
    const formData = watch();

    const geoLocate = async () => {
        setLocating(true);
        // clear current form values and errors
        reset({});
        await getCurrentLocation()
            .then((response) => {
                reset(address);
                setLocating(false);
            })
            .catch((error) => {
                toast.error(error.message);
                setLocating(false);
            });
    };

    const handleOpenModal = async () => {
        setLoading(false);
        const data = await getValues();
        if (!loading && !locating && data) {
            handleAddressChange(data, null, (newAddress) => {
                validateAddress(newAddress)
                    .then((response) => {
                        setLoading(true);
                        toast.warn('Please confirm your address');
                        setModalIsOpen(true);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                        handleCloseModal(true);
                    });
            });
        } else {
            toast.error('Please fill out all fields');
        }
    };

    const handleCloseModal = (e = false) => {
        setModalIsOpen(false);
        if (e) {
            setLoading(false);
        }
    };

    const handleFormSubmit = async (data) => {
        handleCloseModal();
        let destinationKey = 'pickup';
        if (option !== 'from') {
            destinationKey = 'dropOff';
        }
        await handleOrderChange({ data: { [destinationKey]: { address: data } }, setError: setError });
        setLoading(false);
        handleAddressChange();
    };

    return (
        <>
            <h2>{text}</h2>
            <form onSubmit={handleSubmit(handleOpenModal)} id='location-form' data-testid='location-form' className='location-form' noValidate>
                <div className='location-form-child'>
                    <div className='input-group'>
                        <input
                            {...register('line1', {
                                required: locating ? false : 'This is required',
                            })}
                            aria-invalid={errors.line1 ? 'true' : 'false'}
                            type='text'
                            className={errors.line1 ? 'input-box is-invalid' : 'input-box'}
                            placeholder='Street Address'
                            name='line1'
                            disabled={loading || locating}
                        />
                        {errors.line1 && (
                            <span role='alert' className='input-error'>
                                {errors.line1?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <select
                            {...register('state', {
                                required: locating ? false : 'This is required',
                                disabled: loading || locating,
                            })}
                            aria-invalid={errors.state ? 'true' : 'false'}
                            name='state'
                            id='state-selector'
                            className={errors.state ? 'input-box is-invalid' : 'input-box'}
                        >
                            <option value='' style={{ display: 'none' }}>
                                Select Your State
                            </option>
                            {stateList.map((state, index) => (
                                <option key={index} value={state.abbreviation}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                        {errors.state && (
                            <span role='alert' className='input-error'>
                                {errors.state?.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className='location-form-child'>
                    <div className='input-group'>
                        <input
                            {...register('city', {
                                required: locating ? false : 'This is required',
                                disabled: loading || locating,
                            })}
                            aria-invalid={errors.city ? 'true' : 'false'}
                            type='text'
                            className={errors.city ? 'input-box is-invalid' : 'input-box'}
                            placeholder='City'
                            name='city'
                        />
                        {errors.city && (
                            <span role='alert' className='input-error'>
                                {errors.city?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <input
                            {...register('zipCode', {
                                required: locating ? false : 'This is required',
                                disabled: loading || locating,
                            })}
                            type='text'
                            className={errors.zipCode ? 'input-box is-invalid' : 'input-box'}
                            placeholder='ZIP Code'
                            name='zipCode'
                        />
                        {errors.zipCode && (
                            <span role='alert' className='input-error'>
                                {errors.zipCode?.message}
                            </span>
                        )}
                    </div>
                </div>
                {option === 'from' && (
                    <Button
                        data-testid='geo-locate'
                        type='button'
                        className='outlined-button'
                        text={locating ? 'Loading...' : 'Use My Location'}
                        action={loading || locating ? null : geoLocate}
                        customIcon={locating ? <ScaleLoader color='#0093d0' /> : <CarLocation />}
                    />
                )}

                <div className='action-buttons'>
                    <Button
                        className='outlined-button'
                        action={loading || locating ? () => {} : () => moveStep(-1)}
                        text='Back'
                        icon={1}
                        type='button'
                    />
                    <Button
                        data-testid='Next'
                        className='red-button'
                        text={loading ? '' : 'Next'}
                        icon={loading ? 0 : 2}
                        customIcon={loading ? <ScaleLoader color='white' /> : null}
                        type='submit'
                    />
                </div>
            </form>
            <Modal
                data-testid='modal'
                overlayClassName='confirmation-modal-overlay'
                className='confirmation-modal-content'
                isOpen={modalIsOpen}
                ariaHideApp={false}
            >
                <h2>Confirm Address</h2>
                <p>{address.line1}</p>
                <p>
                    {address.city}, {address.state} {address.zipCode}
                </p>
                <div className='action-buttons modal-buttons'>
                    <Button className='outlined-button' action={() => handleCloseModal(true)} text='Cancel' />
                    <Button className='red-button' action={() => handleFormSubmit(formData)} text='Confirm' />
                </div>
            </Modal>
        </>
    );
};

export default LocationForm;
