import { useState } from 'react';
import Button from '../../includes/button';
import ToggleSwitch from '../../toggle';
import TimeController from '../timeController';
import { useApi } from '../../../hooks/api';
import { useAuth } from '../../../contexts/auth';
import { useForm } from 'react-hook-form';
import ScaleLoader from 'react-spinners/ScaleLoader';

const CompanySchedule = ({ provider, setProvider, setSignupStep }) => {
    const [companyTimeSlots, setCompanyTimeSlots] = useState(provider?.schedule || {});
    const { access, refresh, logout } = useAuth();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const api = useApi(access, refresh, logout);
    const [loading, setLoading] = useState(false);
    //set active days of the week
    const [activeDays, setActiveDays] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
    });

    //toggle day active
    const handleToggleDay = (day) => {
        setActiveDays((prevActiveDays) => ({
            ...prevActiveDays,
            [day]: !prevActiveDays[day],
        }));
        //capitalize the first letter of the day
        const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
        //if activeDays[day] is false, remove companyTimeSlots[capitalizedDay]
        if (activeDays[day]) {
            const { [capitalizedDay]: _, ...rest } = companyTimeSlots;
            setCompanyTimeSlots(rest);
        }
    };

    const handleSetCompanySchedule = (data) => {
        //check if key exists in companyTimeSlots
        if (Object.keys(companyTimeSlots).includes(Object.keys(data)[0])) {
            //if key exists, update the value
            setCompanyTimeSlots((prevCompanyTimeSlots) => ({
                ...prevCompanyTimeSlots,
                [Object.keys(data)[0]]: data[Object.keys(data)[0]],
            }));
        } else {
            //if key doesn't exist, add it
            setCompanyTimeSlots((prevCompanyTimeSlots) => ({
                ...prevCompanyTimeSlots,
                ...data,
            }));
        }
    };
    //set edit all days individually
    const [allDays, setAllDays] = useState(false);

    const handleToggleAllDays = () => {
        setAllDays(!allDays);
        setCompanyTimeSlots({});
    };

    const activeDayNames = Object.entries(activeDays)
        .filter(([_, isActive]) => isActive)
        .map(([dayName, _]) => {
            // Capitalize the first letter of each word
            const capitalizedDayName = dayName.toLowerCase().replace(/^(.)(.*)$/, (_, firstLetter, rest) => firstLetter.toUpperCase() + rest);
            return capitalizedDayName;
        });

    const handleMoveStep = async (data) => {
        setLoading(true);
        //check to see all timeslots have a to and from time, and if not return
        const timeSlots = Object.values(companyTimeSlots);
        for (let i = 0; i < timeSlots.length; i++) {
            if (timeSlots[i][0].from === '' || timeSlots[i][0].to === '') {
                setError('activeDays', { type: 'custom', message: 'Please ensure the "To" and "From" is filled out for each day' });
                setLoading(false);
                return;
            }
        }
        await api
            .authPatch({
                url: `/providers/${provider.uuid}/`,
                body: { schedule: companyTimeSlots },
                setError: setError,
            })
            .then((results) => {
                if (results.success) {
                    setProvider(results.response.data);
                    setLoading(false);
                    setSignupStep(4);
                } else {
                    console.log(results);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    return (
        <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleMoveStep)} className='signup-form-content'>
            <h4>Schedule Details</h4>
            <p>Please provide us with your company's schedule details.</p>
            <div className='active-hours schedule-section'>
                <h3>Active Days</h3>
                <div className='edit-days'>
                    <ToggleSwitch onToggle={handleToggleAllDays} currentState={allDays} />
                    <p>Edit each day separately</p>
                </div>
                <hr />
                {allDays ? (
                    <>
                        <div className='active-days'>
                            <h3>Active days of the week</h3>
                            <hr />
                            <div {...register('activeDays')} className='days schedule-section'>
                                <div className={`day ${activeDays.sunday ? 'active' : ''}`} onClick={() => handleToggleDay('sunday')}>
                                    <p>Su</p>
                                </div>
                                <div className={`day ${activeDays.monday ? 'active' : ''}`} onClick={() => handleToggleDay('monday')}>
                                    <p>Mo</p>
                                </div>
                                <div className={`day ${activeDays.tuesday ? 'active' : ''}`} onClick={() => handleToggleDay('tuesday')}>
                                    <p>Tu</p>
                                </div>
                                <div className={`day ${activeDays.wednesday ? 'active' : ''}`} onClick={() => handleToggleDay('wednesday')}>
                                    <p>We</p>
                                </div>
                                <div className={`day ${activeDays.thursday ? 'active' : ''}`} onClick={() => handleToggleDay('thursday')}>
                                    <p>Th</p>
                                </div>
                                <div className={`day ${activeDays.friday ? 'active' : ''}`} onClick={() => handleToggleDay('friday')}>
                                    <p>Fr</p>
                                </div>
                                <div className={`day ${activeDays.saturday ? 'active' : ''}`} onClick={() => handleToggleDay('saturday')}>
                                    <p>Sa</p>
                                </div>
                            </div>
                        </div>

                        <div className='schedule-section'>
                            {activeDayNames.map((dayName) => (
                                <div key={dayName} className='name-fix'>
                                    <h3>{dayName}</h3>
                                    <TimeController day={dayName} handleSetCompanySchedule={handleSetCompanySchedule} />
                                    <hr />
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className='allDays schedule-section'>
                        <h3>Everyday</h3>
                        <TimeController day={'Everyday'} handleSetCompanySchedule={handleSetCompanySchedule} />
                    </div>
                )}
                <div>
                    {errors.activeDays && (
                        <span role='alert' className='input-error'>
                            {errors.activeDays?.message}
                        </span>
                    )}
                </div>
                <Button text={loading ? '' : 'Continue'} icon={2} className='red-button' type='submit' customIcon={loading ? <ScaleLoader color='white' /> : null} />
            </div>
        </form>
    );
};

export default CompanySchedule;
