export function OverviewChart({ children }) {
    return (
        <div className='overview-chart'>
            <h3>Example Job</h3>
            {children}
        </div>
    );
}

export function OverviewChartItem({ name, price, last }) {
    return (
        <>
            <div className='chart-item'>
                <p>{name}</p>
                <p className='e-price'>{price}</p>
            </div>
            {last ? '' : <hr />}
        </>
    );
}
