import { useState, useContext, useEffect } from 'react';
import { TowService, TowPricing } from '../../components/pricing/towPricing';
import { BatteryService, BatteryPricing } from '../../components/pricing/batteryPricing';
import { FuelService, FuelPricing } from '../../components/pricing/fuelPricing';
import { FlatService, FlatPricing } from '../../components/pricing/flatPricing';
import { LockoutService, LockoutPricing } from '../../components/pricing/lockoutPricing';
import { StuckService, StuckPricing } from '../../components/pricing/stuckPricing';

import './priceManagement.scss';
import { ReactComponent as LeftCircle } from '../../assets/svg/leftCircle.svg';
import { ReactComponent as RightCircle } from '../../assets/svg/rightCircle.svg';
import { useApi } from '../../hooks/api';
import { toast } from 'react-toastify';
import ScaleLoader from 'react-spinners/ScaleLoader';

export default function PriceManagement({ provider, setProvider, authContext, updatePage }) {
    const { access, refresh, logout } = authContext;
    const api = useApi(access, refresh, logout);
    const [serviceType, setServiceType] = useState(0);
    const [service, setService] = useState(null);
    const [allServices, setAllServices] = useState(provider?.services ? provider.services : []);
    const [loading, setLoading] = useState(false);

    function getService() {
        setLoading(true);
        setService((prevState) => {
            const currentService = allServices.find((item) => {
                return item.type === serviceType;
            });
            if (!currentService) {
                setService({
                    type: serviceType,
                    active: false,
                });
            } else {
                setService(currentService);
            }
        });
        setLoading(false);
    }

    const updateServiceFee = (name, value) => {
        setService((prevState) => {
            // If fees array doesn't exist, create it and add the new fee
            if (!prevState.fees) {
                return {
                    ...prevState,
                    fees: [{ type: name, amount: value }],
                };
            }

            // If fees array exists but doesn't contain the fee, add it
            const feeIndex = prevState.fees.findIndex((fee) => fee.type === name);
            if (feeIndex === -1) {
                return {
                    ...prevState,
                    fees: [...prevState.fees, { type: name, amount: value }],
                };
            }

            // If fees array exists and contains the fee, update its amount
            return {
                ...prevState,
                fees: prevState.fees.map((fee) => (fee.type === name ? { ...fee, amount: value } : fee)),
            };
        });
    };

    const handleFormSubmit = async ({ data, setError }) => {
        console.log('formdata', data);
        let apiFn;
        let apiData = {
            body: {
                type: serviceType,
                provider: provider.uuid,
                active: data.active,
                fees: Object.entries(data)
                    .filter(([key, value]) => {
                        console.log('value', value);
                        return /^-?\d+$/.test(key);
                    })
                    .map(([key, value]) => {
                        console.log(`Key: ${key}, Value: ${value}`);
                        return {
                            type: key,
                            amount: !value ? 0.0 : Number(value),
                        };
                    }),
            },
            setError: setError,
        };
        if (service?.uuid) {
            apiFn = api.authPatch;
            apiData = {
                ...apiData,
                url: `/services/${service.uuid}/`,
            };
        } else {
            apiFn = api.authPost;
            apiData = {
                ...apiData,
                url: `/services/`,
            };
        }
        setLoading(true);
        await apiFn(apiData)
            .then((results) => {
                if (results.success) {
                    setService(results.response.data);
                    setAllServices((prevState) => {
                        if (
                            allServices.find((item) => {
                                return item.type === prevState.type;
                            })
                        ) {
                            return prevState.map((item) => {
                                if (item.type === prevState.type) {
                                    return results.response.data;
                                }
                                return item;
                            });
                        } else {
                            return [...prevState, results.response.data];
                        }
                    });
                    updatePage();
                    setLoading(false);
                    toast.success('Service saved successfully.');
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error('Something went wrong. Please try again later.');
            });
        setLoading(false);
    };

    const toggleServiceStatus = async ({ val, setError }) => {
        setLoading(true);
        if (service?.uuid) {
            await api
                .authPatch({
                    url: `/services/${service.uuid}/`,
                    body: {
                        active: val,
                    },
                    setError: setError,
                })
                .then((results) => {
                    if (results.success) {
                        setService(results.response.data);
                        setAllServices((prevData) => {
                            return prevData.map((item) => {
                                if (item.type === prevData.type) {
                                    return results.response.data;
                                }
                                return item;
                            });
                        });
                    } else {
                        setLoading(false);
                        console.log(results);
                        toast.error('Something went wrong. Please try again later.');
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                    toast.error('Something went wrong. Please try again later.');
                });
        } else {
            setService((prevState) => {
                return {
                    ...prevState,
                    active: val,
                };
            });
            setAllServices((prevState) => {
                const newServices = prevState.map((item) => {
                    if (item.type === serviceType) {
                        return {
                            ...item,
                            active: val,
                        };
                    }
                    return item;
                });
                return newServices;
            });
        }
        setLoading(false);
    };

    function moveStep(increment) {
        const newStep = serviceType + increment;
        if (newStep < 0 || newStep > 5) {
            return;
        }
        setService(null);
        setServiceType(newStep);
    }

    function setStep(step) {
        setService(null);
        setServiceType(step);
    }

    useEffect(() => {
        getService();
    }, [serviceType]);

    return loading ? (
        <ScaleLoader color='#FF6600' loading={loading} size={150} />
    ) : (
        <div className='page-container price-page-fix'>
            <div className='top-bar-service-selector light-shadow'>
                <LeftCircle className={serviceType === 0 ? 'grey-circle' : 'dark-circle'} onClick={() => moveStep(-1)} />
                <div className='service-type-selector '>
                    <TowService isActive={serviceType === 0} setStep={setStep} />
                    <BatteryService isActive={serviceType === 1} setStep={setStep} />
                    <FuelService isActive={serviceType === 2} setStep={setStep} />
                    <FlatService isActive={serviceType === 3} setStep={setStep} />
                    <LockoutService isActive={serviceType === 4} setStep={setStep} />
                    <StuckService isActive={serviceType === 5} setStep={setStep} />
                </div>
                <RightCircle className={serviceType === 5 ? 'grey-circle' : 'dark-circle'} onClick={() => moveStep(1)} />
            </div>

            <div className='service-box light-shadow'>
                {serviceType === 0 && (
                    <TowPricing service={service} updateServiceFee={updateServiceFee} handleFormSubmit={handleFormSubmit} toggleServiceStatus={toggleServiceStatus} loading={loading} />
                )}
                {serviceType === 1 && (
                    <BatteryPricing service={service} updateServiceFee={updateServiceFee} handleFormSubmit={handleFormSubmit} toggleServiceStatus={toggleServiceStatus} loading={loading} />
                )}
                {serviceType === 2 && (
                    <FuelPricing service={service} updateServiceFee={updateServiceFee} handleFormSubmit={handleFormSubmit} toggleServiceStatus={toggleServiceStatus} loading={loading} />
                )}
                {serviceType === 3 && (
                    <FlatPricing service={service} updateServiceFee={updateServiceFee} handleFormSubmit={handleFormSubmit} toggleServiceStatus={toggleServiceStatus} loading={loading} />
                )}
                {serviceType === 4 && (
                    <LockoutPricing service={service} updateServiceFee={updateServiceFee} handleFormSubmit={handleFormSubmit} toggleServiceStatus={toggleServiceStatus} loading={loading} />
                )}
                {serviceType === 5 && (
                    <StuckPricing service={service} updateServiceFee={updateServiceFee} handleFormSubmit={handleFormSubmit} toggleServiceStatus={toggleServiceStatus} loading={loading} />
                )}
            </div>
        </div>
    );
}
