import React, { useState, useEffect } from 'react';
import { ReactComponent as RightArrow } from '../../assets/svg/rightarrow.svg';
import { FormatPhoneNumber } from '../includes/validators';
import Button from '../includes/button';
import { toast } from 'react-toastify';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useForm } from 'react-hook-form';
import { useApi } from '../../hooks/api';

export default function PhoneConfirmation({ moveStep, step, order, handleOrderChange }) {
    const api = useApi();
    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            number: order?.vehicle?.customer.primaryPhone,
        },
    });
    const [phone, setPhone] = useState(order?.vehicle?.customer.primaryPhone || watch('number'));
    const [loading, setLoading] = useState(false);

    //confirmation code state
    const [waitingForConfirmation, setWaitingForConfirmation] = useState(!!order?.vehicle?.customer.primaryPhone?.number);

    const handleFormSubmit = async (data) => {
        setLoading(true);
        if (waitingForConfirmation) {
            await api
                .post({
                    // get the uuid of the phone number. It's currently not working.
                    url: `/phone-numbers/${phone.uuid}/verify/`,
                    body: {
                        code: data.code.toUpperCase(),
                    },
                    setError: setError,
                })
                .then((results) => {
                    if (results.success) {
                        if (results.response.data.message === 'Verified') {
                            toast.success('Phone number is verified!');
                            handleOrderChange({
                                vehicle: { ...order.vehicle, customer: { ...order.vehicle.customer, primaryPhone: results.response.data } },
                            });
                        }
                        setLoading(false);
                    }
                });
        } else {
            if (errors.checkbox?.message) {
                setError('checkbox', { type: 'custom', message: 'You must accept to continue' });
                setLoading(false);
                return;
            }
            await api
                .patch({
                    url: `/customers/${order.vehicle.customer.uuid}/`,
                    body: {
                        primaryPhone: {
                            number: FormatPhoneNumber(data.number).digitsOnly,
                        },
                    },
                    setError: setError,
                })
                .then((results) => {
                    if (results.success) {
                        setPhone(results.response.data.primaryPhone);
                        setWaitingForConfirmation(true);
                        api.post({
                            url: `/phone-numbers/${results.response.data.primaryPhone.uuid}/send-confirmation-code/`,
                            body: {},
                        });
                    }
                    setLoading(false);
                });
        }
        setLoading(false);
    };

    return (
        <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleFormSubmit)}>
            <div className='phone-input-container'>
                {waitingForConfirmation ? (
                    <>
                        <h2>Enter Confirmation Code</h2>
                        <div className='phone-number'>
                            <input
                                {...register('code', {
                                    required: 'This is required.',
                                    maxLength: {
                                        value: 8,
                                        message: 'Confirmation code must be 8 characters long',
                                    },
                                })}
                                aria-invalid={errors.code ? 'true' : 'false'}
                                type='text'
                                className={errors.code ? 'input-box is-invalid' : 'input-box'}
                                name='code'
                                placeholder='ABCD5678'
                            />
                            {/* <a>Send again in 1:50</a> */}
                            {errors.code && (
                                <span role='alert' className='input-error'>
                                    {errors.code?.message}
                                </span>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <h2>Confirm Your Phone Number</h2>
                        <div className='phone-number'>
                            <input
                                {...register('number', {
                                    required: 'This is required.',
                                    pattern: {
                                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                                        message: 'Please enter a valid phone number',
                                    },
                                    onChange: (e) => FormatPhoneNumber(e.target.value).formattedNumber,
                                })}
                                aria-invalid={errors.number ? 'true' : 'false'}
                                type='text'
                                className={errors.number ? 'input-box is-invalid' : 'input-box'}
                                id='phone'
                                placeholder='(111) 111-1111'
                            />
                            {errors.number && (
                                <span role='alert' className='input-error'>
                                    {errors.number?.message}
                                </span>
                            )}
                            <div>
                                <input
                                    {...register('checkbox', { required: 'You must accept to continue' })}
                                    type='checkbox'
                                    id='smsConsent'
                                    value={true}
                                />
                                <label htmlFor='checkbox'>
                                    I consent to receiving text messages from Textatow for the purpose of this service request. Message & data rates
                                    may apply. Reply CANCEL to cancel request.
                                </label>
                            </div>
                            {errors.checkbox && (
                                <span role='alert' className='input-error'>
                                    {errors.checkbox?.message}
                                </span>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className='action-buttons'>
                <Button
                    className='outlined-button'
                    action={
                        loading
                            ? () => {}
                            : () => {
                                  moveStep(-1);
                              }
                    }
                    text='Back'
                    icon={1}
                />
                {step === 3 ? (
                    waitingForConfirmation ? (
                        <Button
                            className='red-button'
                            text={loading ? '' : 'Confirm'}
                            customIcon={loading ? <ScaleLoader color='white' /> : null}
                            type='submit'
                        />
                    ) : (
                        <Button
                            className='red-button'
                            text={loading ? '' : 'Send Code'}
                            customIcon={loading ? <ScaleLoader color='white' /> : null}
                            type='submit'
                        />
                    )
                ) : (
                    <Button
                        className='red-button'
                        action={() => moveStep(1)}
                        text={loading ? '' : 'Next'}
                        customIcon={loading ? <ScaleLoader color='white' /> : <RightArrow className='icon' />}
                    />
                )}
            </div>
        </form>
    );
}
