import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import './main.scss';

import BaseLayout from './appLayout/base';
import Home from './pages/home';
import Login from './pages/login';
import Location from './pages/location';
import PriceManagement from './pages/priceManagement';
import SignupCompany from './pages/signupCompany';
import Contact from './pages/contact';
import About from './pages/about';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import TowComp from './pages/towCompanies';
import Signup from './pages/signup';
import E404 from './pages/404';
import ForgotPassword from './pages/forgotPassword';
import EmailConfirmation from './pages/emailConfirmation';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/dashboard';
import StateInfo from './pages/statesOfService';
import states from './pages/statesOfService/stateFlags';

const appRoutes = () => {

    return (
            <Route path='/' element={<BaseLayout />} errorElement={<E404 />}>
                <Route element={<Login />} path='signin' exact />
                <Route element={<Signup />} path='signup/' />
                <Route element={<SignupCompany />} path='signup/*' exact />
                <Route element={<ForgotPassword />} path='reset-password/*' exact />
                <Route element={<Dashboard />} path='dashboard' exact />
                <Route element={<PriceManagement />} path='manage-prices' exact />
                
                <Route index element={<Home />} />
                <Route element={<Location />} path='location' exact />
                <Route element={<Contact />} path='contact' exact />
                <Route element={<About />} path='about' exact />
                <Route element={<TowComp />} path='we-want-you' exact />
                <Route element={<EmailConfirmation />} path='confirm-email/*' exact />
                <Route element={<Terms />} path='terms/' exact />
                <Route element={<Privacy />} path='privacy/' exact />
                <Route element={<E404 />} path='*' />

                <Route element={<Home selectedSchema={'mesaAzSchema'} />} path='mesa-roadside-assistance/' />
                <Route element={<Home selectedSchema={'queenCreekAzSchema'} />} path='queen-creek-roadside-assistance/' />
                <Route element={<Home selectedSchema={'gilbertAzSchema'} />} path='gilbert-roadside-assistance/' />
                <Route element={<Home selectedSchema={'chandlerAzSchema'} />} path='chandler-roadside-assistance/' />

                {states.map((state) => (
                    <Route
                    key={state.abbreviation}
                    element={<StateInfo stateNames={{ long: state.name, short: state.abbreviation }} stateInfo={`There are 100+ service providers in ${state.name}. With ~100+ Zip Codes.`} />}
                    path={`${state.name.replace(/\s/g, '')}-${state.abbreviation}`}
                    exact
                    />
                ))}
            </Route>
    );
};

const browserRouter = createBrowserRouter(
    createRoutesFromElements(appRoutes()),
)

export const TatRouter = () => {
    return <RouterProvider router={browserRouter} />
}
