import React from 'react';
import './privacy.scss';

const Privacy = () => {
    return (
        <div className='privacy-container'>
            <div id='outputPage' class='ContractText'>
                <div class='format-html'>
                    <div data-exp='simple2' class='outputVersion1'>
                        <div class=' header'>
                            <span class='content'>Website Privacy Policy</span>
                            <span class='pageNumbers'>
                                Page <span class='currentPageNum'></span> of <span class='totalPageNum'></span>
                            </span>
                        </div>
                        <div class=' footer'></div>
                        <div class=' firstHeader'></div>
                        <div class=' firstFooter'>
                            <span class='pageNumbers'>
                                Page <span class='currentPageNum'></span> of <span class='totalPageNum'></span>
                            </span>
                        </div>
                        <p style={{ textAlign: 'center' }} class='documentTitle'>
                            <strong>textatow.com Privacy Policy</strong>
                        </p>
                        <p style={{ textAlign: 'center' }}>
                            Type of website: Ecommerce<br></br>Effective date: 1st day of November, 2023
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            textatow.com (the "Site") is owned and operated by Christopher Wright. Christopher Wright can be contacted at: <br></br>
                            <br></br>cwright@textatow.org<br></br>(720) 393-0660<br></br>21030 E Desert Hills Circle
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>
                                <u>Purpose</u>
                            </strong>
                            <br></br>The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Site of the following:
                        </p>
                        <ol start='1'>
                            <li value='1'>
                                <span>The personal data we will collect;</span>
                                <br></br>
                            </li>
                            <li value='2'>
                                <span>Use of collected data;</span>
                                <br></br>
                            </li>
                            <li value='3'>
                                <span>Who has access to the data collected;</span>
                                <br></br>
                            </li>
                            <li value='4'>
                                <span>The rights of Site users; and</span>
                                <br></br>
                            </li>
                            <li value='5'>
                                <span>The Site's cookie policy.</span>
                                <br></br>
                            </li>
                        </ol>
                        <p style={{ textAlign: 'left' }}>This Privacy Policy applies in addition to the terms and conditions of our Site.</p>
                        <div>
                            <p style={{ textAlign: 'left' }}>
                                <strong>
                                    <u>Consent</u>
                                </strong>
                                <br></br>By using our Site users agree that they consent to:
                            </p>
                            <ol start='1' data-id='omOl1' has-div='false'>
                                <li value='1'>
                                    <span>The conditions set out in this Privacy Policy; and</span>
                                    <br></br>
                                </li>
                                <li value='2'>
                                    <span>The collection, use, and retention of the data listed in this Privacy Policy.</span>
                                    <br></br>
                                </li>
                            </ol>
                        </div>
                        <p style={{ textAlign: 'left' }}>
                            <strong>
                                <u>Personal Data We Collect</u>
                            </strong>
                            <br></br>We only collect data that helps us achieve the purpose set out in this Privacy Policy. We will not collect any additional data beyond the data listed below without
                            notifying you first.<br></br>
                            <br></br>
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <u>Data Collected Automatically</u>
                            <br></br>When you visit and use our Site, we may automatically collect and store the following information:
                        </p>
                        <ol start='1'>
                            <li value='1'>
                                <span>Location; and</span>
                                <br></br>
                            </li>
                            <li value='2'>
                                <span>Hardware and software details.</span>
                                <br></br>
                            </li>
                        </ol>
                        <p style={{ textAlign: 'left' }}>
                            <u>Data Collected in a Non-Automatic Way</u>
                            <br></br>We may also collect the following data when you perform certain functions on our Site:
                        </p>
                        <ol start='1'>
                            <li value='1'>
                                <span>First and last name;</span>
                                <br></br>
                            </li>
                            <li value='2'>
                                <span>Email address;</span>
                                <br></br>
                            </li>
                            <li value='3'>
                                <span>Phone number;</span>
                                <br></br>
                            </li>
                            <li value='4'>
                                <span>Address;</span>
                                <br></br>
                            </li>
                            <li value='5'>
                                <span>Payment information; and</span>
                                <br></br>
                            </li>
                            <li value='6'>
                                <span>Auto fill data.</span>
                                <br></br>
                            </li>
                        </ol>
                        <p style={{ textAlign: 'left' }}>This data may be collected using the following methods:</p>
                        <ol start='1'>
                            <li value='1'>
                                <span>User inputs the data.</span>
                                <br></br>
                            </li>
                        </ol>
                        <p style={{ textAlign: 'left' }}>
                            <strong>
                                <u>How We Use Personal Data</u>
                            </strong>
                            <br></br>Data collected on our Site will only be used for the purposes specified in this Privacy Policy or indicated on the relevant pages of our Site. We will not use your
                            data beyond what we disclose in this Privacy Policy.<br></br>
                            <br></br>The data we collect automatically is used for the following purposes:
                        </p>
                        <ol start='1'>
                            <li value='1'>
                                <span>To identify the location of the user for the purpose of providing roadside services; and</span>
                                <br></br>
                            </li>
                            <li value='2'>
                                <span>To understand what devices customers use textatow.com on.</span>
                                <br></br>
                            </li>
                        </ol>
                        <p style={{ textAlign: 'left' }}>The data we collect when the user performs certain functions may be used for the following purposes:</p>
                        <ol start='1'>
                            <li value='1'>
                                <span>To allow the User to request Roadside service or create/edit a profile.</span>
                                <br></br>
                            </li>
                        </ol>
                        <p style={{ textAlign: 'left' }}>
                            <strong>
                                <u>Who We Share Personal Data With</u>
                            </strong>
                            <br></br>
                            <u>Employees</u>
                            <br></br>We may disclose user data to any member of our organization who reasonably needs access to user data to achieve the purposes set out in this Privacy Policy.
                        </p>
                        <div>
                            <p style={{ textAlign: 'left' }}>
                                <u>Other Disclosures</u>
                                <br></br>We will not sell or share your data with other third parties, except in the following cases:
                            </p>
                            <ol start='1' data-id='omOl2' has-div='false'>
                                <li value='1'>
                                    <span>If the law requires it;</span>
                                    <br></br>
                                </li>
                                <li value='2'>
                                    <span>If it is required for any legal proceeding;</span>
                                    <br></br>
                                </li>
                                <li value='3'>
                                    <span>To prove or protect our legal rights; and</span>
                                    <br></br>
                                </li>
                                <li value='4'>
                                    <span>To buyers or potential buyers of this company in the event that we seek to sell the company.</span>
                                    <br></br>
                                </li>
                            </ol>
                            <p style={{ textAlign: 'left' }}>
                                If you follow hyperlinks from our Site to another Site, please note that we are not responsible for and have no control over their privacy policies and practices.
                            </p>
                        </div>
                        <p style={{ textAlign: 'left' }}>
                            <strong>
                                <u>How Long We Store Personal Data</u>
                            </strong>
                            <br></br>User data will be stored for ten years.<br></br>
                            <br></br>You will be notified if your data is kept for longer than this period.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>
                                <u>How We Protect Your Personal Data</u>
                            </strong>
                            <br></br>In order to protect your security, we use the strongest available browser encryption and store all of our data on servers in secure facilities. All data is only
                            accessible to our employees. Our employees are bound by strict confidentiality agreements and a breach of this agreement would result in the employee's termination.
                            <br></br>
                            <br></br>While we take all reasonable precautions to ensure that user data is secure and that users are protected, there always remains the risk of harm. The Internet as a
                            whole can be insecure at times and therefore we are unable to guarantee the security of user data beyond what is reasonably practical.
                        </p>
                        <div>
                            <p style={{ textAlign: 'left' }}>
                                <strong>
                                    <u>Children</u>
                                </strong>
                                <br></br>We do not knowingly collect or use personal data from children under 13 years of age. If we learn that we have collected personal data from a child under 13
                                years of age, the personal data will be deleted as soon as possible. If a child under 13 years of age has provided us with personal data their parent or guardian may
                                contact our privacy officer.
                            </p>
                        </div>
                        <p style={{ textAlign: 'left' }}>
                            <strong>
                                <u>How to Access, Modify, Delete, or Challenge the Data Collected</u>
                            </strong>
                            <br></br>If you would like to know if we have collected your personal data, how we have used your personal data, if we have disclosed your personal data and to who we
                            disclosed your personal data, or if you would like your data to be deleted or modified in any way, please contact our privacy officer here:<br></br>
                            <br></br>Christopher Wright<br></br>cwright@textatow.com<br></br>(720) 393-0660<br></br>21030 E Desert Hills Circle
                        </p>
                        <div>
                            <p style={{ textAlign: 'left' }}>
                                <strong>
                                    <u>Do Not Track Notice</u>
                                </strong>
                                <br></br>Do Not Track ("DNT") is a privacy preference that you can set in certain web browsers. We do not track the users of our Site over time and across third party
                                websites and therefore do not respond to browser-initiated DNT signals.
                            </p>
                        </div>
                        <div>
                            <p style={{ textAlign: 'left' }}>
                                <strong>
                                    <u>How to Opt-Out of Data Collection, Use or Disclosure</u>
                                </strong>
                                <br></br>In addition to the method(s) described in the <em>How to Access, Modify, Delete, or Challenge the Data Collected</em> section, we provide the following
                                specific opt-out methods for the forms of collection, use, or disclosure of your personal data specified below:
                            </p>
                            <ol start='1' data-id='omOl3' has-div='false'>
                                <li value='1'>
                                    <span>You can opt-out of receiving marketing emails. You can opt-out by clicking "unsubscribe" on the bottom of any marketing email.</span>
                                    <br></br>
                                </li>
                            </ol>
                        </div>
                        <div>
                            <p style={{ textAlign: 'left' }}>
                                <strong>
                                    <u>Cookie Policy</u>
                                </strong>
                                <br></br>A cookie is a small file, stored on a user's hard drive by a website. Its purpose is to collect data relating to the user's browsing habits. You can choose to
                                be notified each time a cookie is transmitted. You can also choose to disable cookies entirely in your internet browser, but this may decrease the quality of your user
                                experience.
                            </p>
                            <p style={{ textAlign: 'left' }}>We use the following types of cookies on our Site:</p>
                            <ol start='1' data-id='omOl4' has-div='false'>
                                <li value='1'>
                                    <u>
                                        <span>Functional cookies</span>
                                    </u>
                                    <br></br>Functional cookies are used to remember the selections you make on our Site so that your selections are saved for your next visits;<br></br>
                                </li>
                                <li value='2'>
                                    <u>
                                        <span>Analytical cookies</span>
                                    </u>
                                    <br></br>Analytical cookies allow us to improve the design and functionality of our Site by collecting data on how you access our Site, for example data on the
                                    content you access, how long you stay on our Site, etc; and<br></br>
                                </li>
                                <li value='3'>
                                    <u>
                                        <span>Third-Party Cookies</span>
                                    </u>
                                    <br></br>Third-party cookies are created by a website other than ours. We may use third-party cookies to achieve the following purposes:<br></br>
                                    <ol start='1' data-id='omOlOl1' has-div='false'>
                                        <li style={{ marginBottom: 18 }} value='1'>
                                            <span>Collecting analytical data to ensure the User has a good experience; and</span>
                                            <br></br>
                                        </li>
                                        <li style={{ marginBottom: 0 }} value='2'>
                                            <span>Collecting analytical data to ensure textatow employees are able to solve any errors a user may encounter.</span>
                                            <br></br>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                        <p style={{ textAlign: 'left' }}>
                            <strong>
                                <u>Modifications</u>
                            </strong>
                            <br></br>This Privacy Policy may be amended from time to time in order to maintain compliance with the law and to reflect any changes to our data collection process. When
                            we amend this Privacy Policy we will update the "Effective Date" at the top of this Privacy Policy. We recommend that our users periodically review our Privacy Policy to
                            ensure that they are notified of any updates. If necessary, we may notify users by email of changes to this Privacy Policy.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>
                                <u>Contact Information</u>
                            </strong>
                            <br></br>If you have any questions, concerns or complaints, you can contact our privacy officer, Christopher Wright, at:<br></br>
                            <br></br>cwright@textatow.com<br></br>(720) 393-0660<br></br>21030 E Desert Hills Circle
                        </p>
                        <div class='LDCopyright'>
                            <p>&copy;2002-2023 LawDepot.com&reg;</p>
                        </div>
                    </div>
                    <br class='t-last-br'></br>
                    <div id='RadEditorStyleKeeper3' style={{ display: 'none' }}></div>
                    <style reoriginalpositionmarker='RadEditorStyleKeeper3' type='text/css' id='additionalPrintCSS'></style>
                    <br class='t-last-br'></br>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
