import './index.scss';
import { useEffect, useState } from 'react';

const CardAccepted = ({ locationData, moveStep, step }) => {
    return (
        <>
            <div className='page-container'>
                <div className='card-accepted'>
                    <h2>Card Validated!</h2>
                    <h3>Check Your text messages!</h3>
                    <p>You will only be charged after confirming the price via text.</p>
                </div>
            </div>
        </>
    );
};

export default CardAccepted;
