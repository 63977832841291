import "./index.scss";
import { useEffect, useState } from 'react';
import Button from "../includes/button";

const CardDenied = ({locationData, moveStep, step, cardError}) => {



    return (
        <>
            <div className="page-container">
                <div className="card-denied">
                    <h2>Card Denied...</h2>
                    <p>We're sorry but your card was denied because of: {{cardError}}</p>
                    <p>Please try again or contact your card issuer.</p>
                </div>
                
                <div className="action-buttons">
                    <Button type="outlined-button" action={() => moveStep(-2)} text="Back to Payment" icon={1} /> 
                </div>
            </div>
        </>
    );
};

export default CardDenied;