import { useState, useEffect } from 'react';
import { ReactComponent as Dollar } from '../../../assets/svg/dollar.svg';

export function FeeTable({ children }) {
    return <div className='fee-table'>{children}</div>;
}

export function FeeItem({ label, name, service, updateServiceFee, register, errors, onFeeChange }) {
    const getServiceFee = (service, name) => {
        let val = service?.hasOwnProperty('fees')
            ? service.fees.find((obj) => {
                  return obj.type === parseInt(name);
              })
            : {};
        return val;
    };

    let serviceFee = getServiceFee(service, name);

    const [serviceFeePrice, setServiceFeePrice] = useState(serviceFee?.amount ? parseFloat(serviceFee.amount) : 0.0);

    const handleInputChange = (event) => {
        let value = parseFloat(event.target.value);
        if (!isNaN(value)) {
            setServiceFeePrice(value);
        } else {
            console.log('invalid input', event.target.value);
        }
    };

    useEffect(() => {
        onFeeChange(name, serviceFeePrice);
        updateServiceFee(name, serviceFeePrice);
    }, [serviceFeePrice]);

    useEffect(() => {
        let tempServiceFee = getServiceFee(service, name);
        if (tempServiceFee) {
            let amount = parseFloat(tempServiceFee.amount);
            if (!isNaN(amount) && amount !== serviceFeePrice) {
                setServiceFeePrice(amount);
            }
        } else {
            updateServiceFee(name, 0.0);
        }
    }, []);

    return (
        <div className='fee-item'>
            <label htmlFor={name}>{label}</label>
            <div className='fee-input'>
                <Dollar className='dollar-icon' />
                <input
                    {...register(name, {
                        pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message: 'Maximum two decimal places allowed.',
                        },
                        setValueAs: (value) => parseFloat(value),
                    })}
                    value={serviceFeePrice}
                    onChange={handleInputChange}
                    type={'number'}
                    step={0.01}
                    name={name}
                    id={name}
                    className={'input-box'}
                    max={9999999}
                    min={0}
                />
            </div>
            {errors[name] && (
                <span role='alert' className='input-error'>
                    {errors[name]?.message}
                </span>
            )}
        </div>
    );
}
