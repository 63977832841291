import { Outlet } from "react-router-dom";

import Header from "../components/includes/header/header";
import Footer from '../components/includes/footer';
import { ToastContainer } from 'react-toastify';
import { AppProviders } from "../contexts/AppProviders";
import JsonLd from "../seoSchemas/jsonLd";
import { bigMetaSchema } from "../seoSchemas/routeSchemas";
import { useSchema } from "../contexts/schema";


export default function BaseLayout() {
    const { chosenSchema } = useSchema();

    return (
        <>
            <AppProviders>
                <JsonLd data={bigMetaSchema[chosenSchema]} />
                <Header />
                    <Outlet />
                <Footer />
                <ToastContainer />
            </AppProviders>
        </>
    );
}