import './index.scss';
import '../../../pages/terms/terms.scss';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

const ProviderTerms = ({ setTermsAccepted }) => {
    const [isAgreeDisabled, setAgreeDisabled] = useState(true);
    const termsRef = useRef();

    if (!setTermsAccepted) {
        toast.error('You must accept the terms of agreement to continue');
    }

    const checkScroll = () => {
        const { scrollHeight, scrollTop, clientHeight } = termsRef.current;
        const bottom = scrollHeight - scrollTop;
        if (Math.abs(bottom - clientHeight) <= 50) {
            setAgreeDisabled(false);
        }
    };

    const handleAgreement = async (event) => {
        if (event.target.checked) {
            setTermsAccepted(true);
        }
    };

    useEffect(() => {
        const termsElement = termsRef.current;
        termsElement.addEventListener('scroll', checkScroll);
        return () => {
            termsElement.removeEventListener('scroll', checkScroll);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAgreeDisabled(false);
        }, 5000); // 5000 milliseconds = 5 seconds

        // Clean up function
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            <div className='signup-form-content'>
                <h4>To continue you must Read and Agree to our Master Services Agreement.</h4>
                <div className='terms-form-content'>
                    <div ref={termsRef}>
                        <div className='terms-container'>
                            <div id='outputPage' className='ContractText'>
                                <div className='format-html'>
                                    <div data-exp='simple2' className='outputVersion1'>
                                        <p>
                                            <strong>MASTER SERVICES AGREEMENT</strong>
                                        </p>
                                        <p>
                                            This Master Services Agreement (&ldquo;Agreement&rdquo;) is made by and between P&amp;D Towing
                                            Connections, Inc., DBA TextaTow, an Arizona for profit corporation (&ldquo;TextaTow&rdquo;), and __Tow
                                            Provider__, an __Business Entity__ (&ldquo;Tow Provider&rdquo;). Each of TextaTow and Tow Provider may be
                                            referred to as &ldquo;Party&rdquo; or collectively as &ldquo;Parties.&rdquo;
                                        </p>
                                        <p>RECITALS</p>
                                        <ol>
                                            <li>
                                                TextaTow utilizes modern technology to comfort stranded motorists by accelerating the process of
                                                securing safe, low cost, and service-oriented tow providers.
                                            </li>
                                            <li>
                                                Tow Provider has specific skills, equipment, vehicles, licenses, and experience that support
                                                TextaTow&rsquo;s business.
                                            </li>
                                            <li>
                                                Both TextaTow and Tow Provider understand and acknowledge that Tow Provider&rsquo;s services shall be
                                                provided to TextaTow as an independent contractor.
                                            </li>
                                        </ol>
                                        <p>
                                            Tow Provider hereby contracts with and agrees to pay TextaTow for Services to be defined in one or more
                                            Statement(s) of Work, which Services shall be performed and delivered in accordance with the following
                                            terms and conditions. In consideration of the mutual promises and covenants contained herein, and for
                                            other good and valuable consideration, the sufficiency of which is hereby acknowledged and agreed, the
                                            Parties hereby agree as follows:
                                        </p>
                                        <ol>
                                            <li>
                                                <strong> Relationship of the Parties.</strong> The Parties agree that Tow Provider will be serving
                                                TextaTow as an independent contractor for all purposes and not as an employee, agent, partner, or
                                                joint venturer with TextaTow. Tow Provider will have control over the order and sequence of the
                                                services, deliverables, and the specific hours worked and will not be subject to withholding by
                                                TextaTow of income or employment taxes. The services to be performed by Tow Provider (the "Services")
                                                are set forth in detail in one or more documents entitled "Statement of Work" (the "SOW"),
                                                incorporated herein. Tow Provider shall be responsible for and shall indemnify and defend TextaTow
                                                against all such taxes or contributions, including but not limited to penalties and interest. Any
                                                persons employed or engaged by Tow Provider in connection with the performance of the Services shall
                                                be Tow Provider&rsquo;s employees or contractors and Tow Provider shall be fully responsible for them
                                                and indemnify and defend TextaTow against any claims made by or on behalf of any such employee or
                                                contractor.
                                            </li>
                                            <li>
                                                <strong> Effective Date and Termination.</strong>
                                            </li>
                                        </ol>
                                        <p>
                                            (a) As stated herein or in the SOW, either Party may terminate this Agreement by providing the other Party
                                            a minimum of __30__ days advance written notice and such termination will be effective as of the date
                                            specified in such notice (the "Termination Date"), provided that such date is no earlier than __30__ days
                                            after the date of delivery of the notice. Tow Provider will continue to provide, and TextaTow will
                                            continue to pay for, the Services until the Termination Date.
                                        </p>
                                        <p>
                                            (b) TextaTow may terminate this Agreement immediately upon written notice to Tow Provider if: (i) Tow
                                            Provider is engaged in or asks TextaTow to engage in or ignore any illegal or unethical activity; (ii) Tow
                                            Provider loses its license to operate its tow services business; (iii) Tow Provider commits an act or
                                            omits to take an act that in the good faith and reasonable belief of TextaTow jeopardized, or could have
                                            jeopardized, TextaTow reputation and/or goodwill in the market; (iv) Tow Provider has criminal charges
                                            filed against her/him/it, which, in the good faith determination of TextaTow are detrimental to the
                                            reputation of TextaTow, which includes but is not limited to fraud, theft, or a conviction of a felony; or
                                            (v) Tow Provider engages in conduct, which is in violation of any provision of this Agreement or any
                                            TextaTow policies, procedures, or rules.
                                        </p>
                                        <p>
                                            (c) In the event that a Party commits a breach of this Agreement, other than for the reasons described in
                                            the above Section 2(b), and fails to cure the same within fifteen (15) business days following delivery by
                                            the non-breaching Party of written notice specifying the nature of the breach, the non-breaching Party may
                                            terminate this Agreement effective upon written notice of such termination.
                                        </p>
                                        <p>
                                            (d) The expiration or termination of this Agreement will not destroy or diminish the binding force and
                                            effect of any of the provisions of this Agreement that expressly, or by reasonable implication, come into
                                            or continue in effect on or after such expiration or termination, including, without limitation,
                                            provisions relating to payment of fees and expenses (including witness fees and expenses),
                                            confidentiality, governing law, arbitration, and limitation of liability.&nbsp;
                                        </p>
                                        <ol start='3'>
                                            <li>
                                                <strong> Tow Provider Indemnification.</strong>
                                            </li>
                                        </ol>
                                        <p>
                                            To the fullest extent allowed by law, Tow Provider agrees to defend, indemnify, and hold TextaTow
                                            harmless, from and against any and all losses, damages, liabilities, claims, demands, actions, judgments,
                                            costs, and legal or other expenses (including, without limitation, reasonable attorneys' fees, costs,
                                            expert costs, and expenses subsequent to the Effective Date) incurred as a result of: (i) claims by
                                            third-parties relating to or arising out of facts and circumstances occurring after the Effective Date and
                                            arising out of this Agreement; or (ii) any breach of, or failure by Tow Provider to perform, any of its
                                            representations, warranties, or covenants set forth in this Agreement.
                                        </p>
                                        <ol start='4'>
                                            {/* IMPORTANT */}
                                            <li>
                                                <strong> Payment Terms.</strong> Payments to Tow Provider are to be made within __30__ business days
                                                of TextaTow&rsquo;s receipt of cleared funds (the &ldquo;Fees&rdquo;). Tow Provider agrees to pay all
                                                expenses associated with the Services, including any third-party fees.&nbsp;
                                            </li>
                                            <li>
                                                <strong> Non-solicitation.</strong> During the Term of this Agreement and for a period of one (1) year
                                                after termination or expiration, Tow Provider shall not, directly or indirectly: (1) solicit, induce,
                                                or attempt to solicit or induce any referral source, contractor, reseller, consultant, employee,
                                                customer, licensee, vendor, or other business relation of TextaTow to cease doing business with
                                                TextaTow, or in any way interfere with the relationship between any employee, contractor, reseller,
                                                consultant, customer, or business relation of TextaTow; or (2) sell to or solicit sales from any
                                                customer or account which was a customer or account of TextaTow as of the termination or expiration of
                                                this Agreement or within one (1) year prior, or with which Tow Provider had contact or about which Tow
                                                Provider had information by reason of Tow Provider&rsquo;s work with TextaTow.
                                            </li>
                                            <li>
                                                <strong> Performance Standards.</strong> In performing Services under this Agreement, Tow Provider
                                                shall (i) use diligent efforts and professional skills and judgment; (ii) perform Services in
                                                accordance with recognized standards of professionalism and licensure; (iii) comply with the
                                                directives, policies, rules, and regulations of TextaTow, (iv) provide Services in an efficient and
                                                cost-effective manner; (v) perform Services in accordance with the statement of work as provided by
                                                TextaTow; and (vi) comply with all applicable federal, state, and local laws and regulations.
                                            </li>
                                            <li>
                                                <strong> Limitation of Liability.</strong> The liability of TextaTow in any and all categories and for
                                                any and all causes arising out of this Agreement, whether based in contract, tort, negligence, strict
                                                liability, or otherwise will, in the aggregate, not exceed the actual fees paid to Tow Provider over
                                                the period of this Agreement. In no event will TextaTow be liable for incidental, consequential,
                                                punitive, indirect, or special damages, including, without limitation, any interruption or loss of
                                                business, profit, or goodwill. As a condition for recovery of any liability, Tow Provider must assert
                                                any claim against TextaTow within three (3) months after discovery or sixty (60) days after the
                                                termination or expiration of this Agreement, whichever is earlier.
                                            </li>
                                            <li>
                                                <strong> Governing Law, Arbitration, Witness Fees, and Injunctive Relief.</strong>
                                            </li>
                                        </ol>
                                        <p>
                                            (a) This Agreement will be governed by and construed in accordance with the laws of the State of Arizona,
                                            without regard to conflicts of law's provisions.
                                        </p>
                                        <p>
                                            (b) The Parties agree that resolution of disputes or claims arising under this Agreement shall be resolved
                                            first by good-faith participation by each of the Parties in mediation utilizing qualified mediators who
                                            are mutually agreed upon by the Parties. If the dispute is not resolved through mediation within thirty
                                            (30) days from the date that either party makes a written demand on the other party to enter into
                                            mediation, and in the absence of a mutual agreement to enter into binding arbitration with an arbitrator
                                            mutually agreed upon by the Parties, either party shall have the right to bring a judicial action to
                                            resolve the dispute through the filing of an action with the court in Maricopa County, Arizona. Unless
                                            otherwise determined by the court and/or by the arbitrator, each of which shall have the power to allocate
                                            the costs of the action or the arbitration, each party shall pay its own attorneys and court costs.
                                        </p>
                                        <p>
                                            (c) In the event TextaTow is requested or authorized by Tow Provider or is required by government
                                            regulation, subpoena, or other legal process to produce documents or appear as witnesses in connection
                                            with any action, suit, or other proceeding initiated by a third-party against Tow Provider or by Tow
                                            Provider against a third-party, Tow Provider will, so long as TextaTow is not a party to the proceeding in
                                            which the information is sought, reimburse TextaTow for its professional's time (based on customary rates)
                                            and expenses, as well as the fees and expenses of its counsel, incurred in responding to such requests.
                                            This provision is in addition to and not in lieu of any indemnification obligations Tow Provider may have
                                            under this Agreement.
                                        </p>
                                        <p>
                                            (d) Each of the Parties acknowledge that a breach or threat of a breach of any provision of this Agreement
                                            by either Party will result in irreparable harm and damage to the non-breaching Party. Consequently, the
                                            non-breaching Party's remedies at law will be inadequate and, in each such event, the non-breaching Party
                                            will be entitled to an injunction or other similar relief to prevent the breaching Party or its executives
                                            from breaching this Agreement and to enforce specifically the provisions hereof, in addition to money
                                            damages sustained by the non-breaching party resulting from a breach or threatened breach of this the
                                            State of Arizona, without regard to conflicts of law's provisions.
                                        </p>
                                        <ol start='9'>
                                            <li>
                                                <strong> Confidential Information.</strong>
                                            </li>
                                        </ol>
                                        <p>
                                            (a) Each Party (the "Receiving Party") will treat as confidential and prevent unauthorized duplication or
                                            disclosure of any confidential or proprietary information, including, without limitation, any information
                                            about the Disclosing Party's (as defined below) products, services, marketing techniques, price lists,
                                            customer lists, pricing policies, business methods, business plans, budgets, projections, financial
                                            information, and technical information (the "Confidential Information") of the other Party (the
                                            "Disclosing Party") which the Receiving Party may acquire during the course of its activities under this
                                            Agreement and will not use any of the Confidential Information for any purpose other than in furtherance
                                            of the Receiving Party's obligations under this Agreement. Notwithstanding the foregoing, the Receiving
                                            Party may disclose Confidential Information of the Disclosing Party to its employees, members, officers,
                                            agents, or consultants (the "Representatives") who need to have access to the Confidential Information,
                                            and who are informed by the Receiving Party of the confidentiality obligations imposed by this Agreement.
                                            The Receiving Party's obligations under this Section will not apply to Confidential Information that (i)
                                            is or becomes part of the public domain through no fault of Receiving Party or its Representatives; (ii)
                                            is known to the Receiving Party at the time of receipt of such information from the Disclosing Party;
                                            (iii) if, after the date hereof, obtained by Receiving Party from a third party who has the legal right to
                                            disclose the same without a restriction on disclosure; (iv) has been, or is subsequently, independently
                                            acquired or developed by the Receiving Party without violating any of the Receiving Party's obligations
                                            under this Agreement; or (v) is required to be disclosed by government regulation, court order or other
                                            legal process; provided the Receiving Party provides the Disclosing Party with prompt notice of such
                                            requirement so that the Disclosing Party may seek a protective order or other appropriate relief. The
                                            Receiving Party acknowledges and understands that any right, title, and interest in and to the Disclosing
                                            Party's Confidential Information are vested in the Disclosing Party. The obligations of confidentiality
                                            provided hereunder will survive for a period of two (2) years after the expiration or termination of this
                                            Agreement for any reason.
                                        </p>
                                        <p>
                                            (b) Each of the Parties acknowledge that the covenants and restrictions contained in this Agreement are
                                            (a) necessary, fundamental, and required for the protection of the business and intellectual property of
                                            each of the Parties; (b) necessary and required to protect the goodwill and value of TextaTow; (c) relate
                                            to matters which are of a special, unique, and extraordinary character that give each of the covenants and
                                            restrictions a special, unique, and extraordinary value; and (d) of a nature such that a breach of any
                                            such covenants or restrictions or any other provision of this Agreement will result in irreparable harm
                                            and damage to TextaTow.
                                        </p>
                                        <ol start='10'>
                                            <li>
                                                <strong> General Provisions.</strong>
                                            </li>
                                        </ol>
                                        <p>
                                            (a) <u>Waiver</u>. Neither Party will be deemed to have waived any rights or remedies accruing under this
                                            Agreement unless such waiver is in writing and signed by the Party electing to waive the right or remedy.
                                        </p>
                                        <p>
                                            (b) <u>Force Majeure</u>. Neither Party will be liable for any delay or failure to perform under this
                                            Agreement (other than with respect to payment obligations) to the extent such delay or failure is a result
                                            of an act of God, war, earthquake, pandemic, civil disobedience, court order, labor dispute, or other
                                            cause beyond such Party's reasonable control.
                                        </p>
                                        <p>
                                            (c) <u>Notices</u>. All notices or other communications provided for or permitted hereunder shall be made
                                            in writing by hand-delivery, pre-paid first-class mail, or by electronic mail:
                                        </p>
                                        <p>If to TextaTow:(Address of Textatow or cwright@textatow.org)&nbsp;</p>
                                        <p>
                                            If to Tow Provider: *Primary address on Tow Provider Account* or *Primary email address on Tow Provider
                                            Account*
                                        </p>
                                        <p>
                                            All such notices and communications shall be deemed to have been duly given: when delivered by hand, if
                                            personally delivered; two (2) business days after being deposited in the mail, postage pre-paid, if mailed
                                            as aforesaid; or on the date of receipt, if transmitted by electronic mail (with electronic confirmation
                                            of receipt) prior to 5:00 p.m. on a business day or otherwise on the next business day, provided receipt
                                            of such transmission shall be confirmed by follow-up notice within seventy-two (72) hours by another
                                            method authorized above. Any party may from time to time, by written notice to the other, designate a
                                            different address which shall be substituted for that specified above.
                                        </p>
                                        <p>
                                            (d) <u>Governing Law; Interpretation; Tax Treatment</u>. This Agreement shall be governed by and construed
                                            in accordance with the laws of the State of Arizona applicable to agreements made and to be performed
                                            within the state. The provisions of this Agreement shall be interpreted in a reasonable manner to affect
                                            the purpose of the Parties and this Agreement. All of the Parties, and their respective legal counsel,
                                            have mutually drafted this Agreement and it shall be interpreted without placing any burden or rule of
                                            construction on either of the parties as being responsible for the drafting of this Agreement. Neither
                                            party makes any representations to the other regarding the proper tax treatment of the transaction
                                            reflected in this Agreement.
                                        </p>
                                        <p>
                                            (e) <u>Court Jurisdiction</u>. Subject to the above provisions of Section 8 of this Agreement, the Parties
                                            hereto agree that the State Courts located in the County of Maricopa, State of Arizona ("Court'') shall
                                            have exclusive jurisdiction over any legal action or other proceeding between the parties regarding this
                                            Agreement (an "Action&rdquo;) and the Parties hereby consent to the exercise of personal jurisdiction over
                                            them by the Court for purposes of resolving the Action.
                                        </p>
                                        <p>
                                            (f) <u>Severability</u>. In the event that any one or more of the provisions contained herein, or the
                                            application thereof in any circumstances, is held invalid, illegal, or unenforceable in any respect for
                                            any reason, the validity, legality, and enforceability of any such provision in every other respect and of
                                            the remaining provisions hereof shall not be in any way impaired or affected, it being intended that all
                                            other rights and privileges shall be enforceable to the fullest extent permitted by law.&nbsp;
                                        </p>
                                        <p>
                                            (g) <u>Attorneys' Fees</u>. In any action or proceeding brought to enforce or interpret any provision of
                                            this Agreement, or where any provision hereof is validly asserted as a defense, the prevailing party shall
                                            be entitled to recover actual attorneys' fees and all other litigation costs including without limitation
                                            costs awardable pursuant to the laws of the State of Arizona and amounts payable to expert witnesses
                                            ("Costs&rdquo;) in addition to any other available legal remedy. In addition to the fees and Costs
                                            recoverable under the preceding sentence, the Parties agree that the prevailing Party shall be entitled to
                                            recover actual attorneys' fees and Costs incurred in connection with the enforcement of a judgment arising
                                            from such action or proceeding.
                                        </p>
                                        <p>
                                            (h) <u>Entire Agreement.</u> This Agreement, including the SOW attached hereto, is intended by the parties
                                            as a final expression of their agreement and is intended to be a complete and exclusive statement of the
                                            agreement and understanding of the parties hereto in respect of the subject matter contained herein. This
                                            Agreement supersedes any and all prior restrictions, promises, representations, warranties, agreements,
                                            understandings, and undertakings between the parties with respect to such subject matter and there are no
                                            restrictions, promises, representations, warranties, agreements, understandings, or undertakings with
                                            respect to such subject matter other than those set forth or referred to herein.&nbsp;
                                        </p>
                                        <p>
                                            (i) <u>Tow Provider Representations and Warranties</u>. Tow Provider represents and warrants to TextaTow
                                            that: (a) Tow Provider has the right to enter into this Agreement, to grant the rights granted herein and
                                            to perform fully all of Tow Provider&rsquo;s obligations in this Agreement in the jurisdiction; (b)
                                            entering into this Agreement with TextaTow and Tow Provider&rsquo;s performance of the Services do not and
                                            will not conflict with or result in any breach or default under any other agreement to which Tow Provider
                                            is subject; (c) Tow Provider has the required skill, experience, and qualifications to perform the
                                            Services and Tow Provider shall perform the Services in a professional and workmanlike manner in
                                            accordance with best industry standards for similar services and Tow Provider shall devote sufficient
                                            resources to ensure that the Services are performed in a timely and reliable manner; (d) Tow Provider
                                            shall perform the Services in compliance with all applicable federal, state, and local laws and
                                            regulations; and (e) Tow Provider shall not reverse engineer or export, directly or indirectly, any data
                                            acquired from TextaTow, or any products or processes utilizing any such data, to any country in violation
                                            of any applicable export laws or regulations.
                                        </p>
                                        <p>
                                            (j) <u>Non-disparagement.</u> Tow Provider agrees to not at any time make any public statements, whether
                                            orally or in writing, that are intended to be derogatory or damaging to TextaTow or any of
                                            TextaTow&rsquo;s officers, employees, directors, partners, agents, or shareholders or take any action that
                                            would reasonably be expected to adversely affect the other TextaTow&rsquo;s personal or professional
                                            reputation.
                                        </p>
                                        <p>
                                            <strong>WHEREFORE</strong>, the Parties have entered and executed this Agreement to be effective as of the
                                            Effective Date.
                                        </p>
                                        <p>
                                            <strong>TEXTATOW</strong> <strong>TOW PROVIDER</strong>
                                        </p>
                                        <p>Name: __Christopher Wright___ Name: _______________________</p>
                                        <p>Title: __Owner, CEO__________ Title: ________________________</p>
                                        <p>Date: __3/28/24______________ Date: ________________________</p>
                                        <p>
                                            <strong>
                                                <u>Exhibit A</u>
                                            </strong>
                                        </p>
                                        <p>
                                            <strong>TextaTow Statement of Work</strong>
                                        </p>
                                        <p>
                                            This Statement of Work (the &ldquo;SOW&rdquo;) made by and is made by and between P&amp;D Towing
                                            Connections, Inc., DBA TextaTow, an Arizona for profit corporation (&ldquo;TextaTow&rdquo;), and __Tow
                                            Provider__, an ________________ (&ldquo;Tow Provider&rdquo;) and is subject in all respects to the terms
                                            of the Master Services Agreement (&ldquo;MSA&rdquo;). All terms specifically defined in the MSA shall have
                                            the same meaning when used in this Statement of Work. In the case of discrepancies between this SOW and
                                            the MSA, this SOW shall control. The terms of this Statement of Work are as follows:
                                        </p>
                                        <p>&nbsp;</p>
                                        <p>
                                            <strong>
                                                <u>Scope of Work: *Tow Providers active services*</u>
                                            </strong>
                                        </p>
                                        <p>
                                            <strong>
                                                <u>Schedule: *Tow Providers active schedule*</u>
                                            </strong>
                                        </p>
                                        <p>
                                            <strong>
                                                <u>Payment Schedule/Rate: bi-monthly/*determined by Textatow*</u>
                                            </strong>
                                        </p>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                        <p>Initials ____C.W.____ Initials __________</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label htmlFor='initials'>Please enter your initials </label>
                    <input type='text' id='initials' name='initials' maxLength={2} />
                    <label htmlFor='terms'>
                        I agree to the Master Services Agreement and acknowledge that all information I give hereafter is honest and truthful.
                    </label>
                    <input type='checkbox' id='terms' name='terms' disabled={isAgreeDisabled} onClick={handleAgreement} />
                </div>
            </div>
        </>
    );
};

export default ProviderTerms;
