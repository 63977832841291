import { useAuth } from '../../../contexts/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/img/mobileLogo.png';
import MobileMenu from '../mobileMenu';
import './header.scss';

const Header = () => {
    const { is_authenticated, logout } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const links = [
        { title: 'Home', path: '/', active: false },
        { title: 'About Us', path: '/about', active: false },
        { title: 'Contact Us', path: '/contact', active: false },
        { title: 'Tow Companies', path: '/we-want-you', active: false },
    ];

    const mobileLinks = [
        { title: 'Home', path: '/', active: false },
        { title: 'About Us', path: '/about', active: false },
        { title: 'Contact Us', path: '/contact', active: false },
        { title: 'Tow Companies', path: '/we-want-you', active: false },
        is_authenticated() ? { title: 'DASHBOARD', path: '/dashboard', active: false } : { title: 'SIGN IN', path: '/signin', active: false },
        is_authenticated() ? { title: 'LOG OUT', path: '/logout', active: false } : { title: 'SIGN UP', path: '/signup', active: false },
    ];

    links.forEach((link) => {
        if (link.path === location.pathname) {
            link.active = true;
        }
    });

    const handleNavigate = (e) => {
        if (e === '/logout') {
            logout();
            navigate('/');
        } else {
            navigate(e);
        }
    };

    return (
        <header>
            <img src={Logo} alt='Textatow Logo' onClick={() => handleNavigate('/')} style={{ cursor: 'pointer' }} />

            <div className='header-menu'>
                <ul className='menu-items'>
                    {links.map((link, index) => (
                        <li
                            key={index}
                            className={link.active ? 'menu-item-active' : ''}
                            onClick={() => handleNavigate(link.path)}
                            style={{ cursor: 'pointer' }}
                        >
                            {link.title}
                        </li>
                    ))}
                </ul>

                <div className={is_authenticated() ? 'right-buttons' : 'right-buttons homepage-provider-login'}>
                    {is_authenticated() ? (
                        <>
                            <button
                                className='login-button red-button'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/dashboard');
                                }}
                            >
                                DASHBOARD
                            </button>
                            <button className='login-button red-button' onClick={() => handleNavigate('/logout')}>
                                LOG OUT
                            </button>
                        </>
                    ) : (
                        <>
                            <p>Providers</p>
                            <div>
                                <button
                                    className='login-button red-button'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/signin');
                                    }}
                                >
                                    SIGN IN
                                </button>
                                <button
                                    className='register-button outlined-button'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/signup');
                                    }}
                                >
                                    SIGN UP
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <MobileMenu links={mobileLinks} handleNavigate={handleNavigate} />
        </header>
    );
};

export default Header;
