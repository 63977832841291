import React, { useState, useEffect } from 'react';
import './toggle.scss';


const ToggleSwitch = ({ onToggle, currentState }) => {
  const [toggle, setToggle] = useState(currentState);


  const handleToggle = () => {
    const newToggleState = !toggle;
    setToggle(newToggleState);
    onToggle(newToggleState);
  };

  return (
    <div className="toggle-item">
        
      <label className="switch">
        <input
          type="checkbox"
          checked={toggle}
          onChange={handleToggle}
        />
        <span className={toggle ? "slider on" : "slider off"}></span>
        
      </label>
      <p className="toggle-state">{toggle ? 'Active' : 'Inactive'}</p>
    </div>
  );
};

export default ToggleSwitch;