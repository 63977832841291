import './location.scss';
import { ReactComponent as CarLocation } from '../../assets/svg/carlocation.svg';
import { ReactComponent as Tow } from '../../assets/svg/tow.svg';
import { ReactComponent as Car } from '../../assets/svg/car.svg';
import { ReactComponent as Phone } from '../../assets/svg/phone.svg';
import { ReactComponent as User } from '../../assets/svg/user.svg';
import { ReactComponent as Payment } from '../../assets/svg/paymentinfo.svg';
import ScaleLoader from 'react-spinners/ScaleLoader';

import LocationForm from '../../components/locationForm';
import PhoneConfirmation from '../../components/phoneConfirmation';
import PersonalInfo from '../../components/personalInformation';
import CarInfo from '../../components/carInfo';
import PaymentForm from '../../components/paymentform';
import CardAccepted from '../../components/paymentSuccess';
import CardDenied from '../../components/paymentFailure';

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/api';

const Location = () => {
    const [loading, setLoading] = useState(true);
    const { state } = useLocation();
    const api = useApi();
    const serviceType = state?.serviceType || 0;
    const navigate = useNavigate();

    // Set Order Data
    const [order, setOrder] = useState({
        serviceType: serviceType,
        pickup: {
            address: {
                line1: null,
                line2: null,
                city: null,
                state: null,
                zipCode: null,
            },
        },
    });
    const orderId = localStorage.getItem('orderId');

    useEffect(() => {
        const lookupOrder = async () => {
            await api
                .get({ url: `/orders/${orderId}/` })
                .then((results) => {
                    if (results.success) {
                        setOrder(results.response.data);
                        setStepFromInitialData(results.response.data);
                    } else {
                        localStorage.setItem('orderId', null);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    localStorage.setItem('orderId', null);
                    setLoading(false);
                });
        };
        // Lookup Order if ID saved to localstorage
        const orderUUID = orderId?.match(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
        if (orderId && orderUUID) {
            lookupOrder();
        } else {
            setLoading(false);
        }
    }, []);

    //set progress bar
    const [step, setStep] = useState(1);
    const steps = [
        { icon: <CarLocation />, label: 'Car Location' },
        { icon: <User className='margin-fix color-fix' />, label: 'User Info' },
        { icon: <Phone className='margin-fix' />, label: 'Phone' },
        { icon: <Car className='margin-fix' />, label: 'Car Details' },
        { icon: <Payment className='margin-fix' />, label: 'Payment' },
    ];
    if (serviceType === 0) {
        steps.splice(4, 0, { icon: <Tow className='margin-fix small-icon red-icon' />, label: 'Tow Location' });
    }

    function setStepFromInitialData(data) {
        if (data.vehicle?.make?.length > 1) {
            if (serviceType === 0 && !data?.dropOff?.address) {
                setStep(5);
            } else {
                setStep(6);
            }
        } else if (data.vehicle?.customer.primaryPhone?.isVerified === true) {
            setStep(4);
        } else if (data.vehicle?.customer.firstName) {
            setStep(3);
        } else if (data.pickup.address.line1?.length > 0) {
            setStep(2);
        }
    }

    function moveStep(increment) {
        let newStep = step + increment;
        if (newStep < 1) {
            navigate('/');
            return;
        } else if (newStep > 7) {
            return;
        } else if (newStep === 5 && serviceType !== 0) {
            // skip step 5 if service isn't tow
            newStep += increment;
        } else if (newStep === 3 && order.vehicle?.customer.primaryPhone?.isVerified === true) {
            newStep += increment;
        }
        setStep(newStep);
    }

    const updateOrCreateOrder = async ({ data, setError = null }) => {
        if (setError) {
            let apiMethod = api.post;
            let url = '/orders/';
            let body = {
                ...order,
                ...data,
            };
            if (order?.uuid) {
                apiMethod = api.patch;
                url += `${order.uuid}/`;
                body = data;
            }
            await apiMethod({ url: url, body: body, setError: setError }).then((results) => {
                if (results.success) {
                    localStorage.setItem('orderId', results.response.data.uuid);
                    setOrder(results.response.data);
                    moveStep(1);
                }
            });
        } else {
            setOrder((prevData) => ({
                ...prevData,
                ...data,
            }));
            moveStep(1);
        }
    };

    const [cardError, setCardError] = useState('');
    return (
        <>
            {loading ? (
                <ScaleLoader color='#1cc5b7' />
            ) : (
                <div className='page-container'>
                    <div className='location light-shadow'>
                        <div className='location-progress-bar'>
                            {steps.map((stepData, index) => (
                                <div
                                    key={index}
                                    className={`location-step ${step === index + 1 ? 'selected-step' : step > index + 1 ? 'finished-step' : ''}`}
                                >
                                    <span className='margin-fix'>{stepData.icon}</span>
                                    <span>{stepData.label}</span>
                                </div>
                            ))}
                        </div>

                        {step === 1 && (
                            <LocationForm
                                moveStep={moveStep}
                                step={step}
                                text="What Is Your Vehicle's Location?"
                                order={order}
                                handleOrderChange={updateOrCreateOrder}
                                option='from'
                            />
                        )}
                        {step === 2 && <PersonalInfo moveStep={moveStep} step={step} order={order} handleOrderChange={updateOrCreateOrder} />}
                        {step === 3 && <PhoneConfirmation moveStep={moveStep} step={step} order={order} handleOrderChange={updateOrCreateOrder} />}
                        {step === 4 && <CarInfo moveStep={moveStep} order={order} handleOrderChange={updateOrCreateOrder} />}
                        {step === 5 && serviceType === 0 && (
                            <LocationForm
                                moveStep={moveStep}
                                step={step}
                                text='Where Would You Like to be Towed?'
                                order={order}
                                handleOrderChange={updateOrCreateOrder}
                                option='to'
                            />
                        )}
                        {step === 6 && <PaymentForm moveStep={moveStep} step={step} order={order} handleOrderChange={updateOrCreateOrder} />}
                        {step === 7 && <CardAccepted moveStep={moveStep} step={step} order={order} handleOrderChange={updateOrCreateOrder} />}
                        {step === 8 && <CardDenied moveStep={moveStep} step={step} order={order} handleOrderChange={updateOrCreateOrder} />}
                    </div>
                </div>
            )}
        </>
    );
};

export default Location;
