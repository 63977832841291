import { useState, createContext, useContext } from 'react';

export const SchemaContext = createContext({chosenSchema: 'defaultSchema'});


export const SchemaProvider = ({ children }) => {
    const [chosenSchema, setChosenSchema] = useState('defaultSchema');

    return <SchemaContext.Provider value={{ chosenSchema, setChosenSchema }}>{children}</SchemaContext.Provider>;
}


export const useSchema = () => useContext(SchemaContext)