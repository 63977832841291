import React, { useState, useEffect } from 'react';
import { ReactComponent as Delete } from '../../../assets/svg/delete.svg';
import { ReactComponent as CarLocation } from '../../../assets/svg/carlocation.svg';
import { ReactComponent as PlusBox } from '../../../assets/svg/plusbox.svg';
import { FormatPhoneNumber } from '../../includes/validators';
import Button from '../../includes/button';
import { useAuth } from '../../../contexts/auth';
import { useApi } from '../../../hooks/api';
import { useForm } from 'react-hook-form';
import ScaleLoader from 'react-spinners/ScaleLoader';
import stateArray from '../../../pages/statesOfService/stateFlags';
import ProviderTerms from '../termsOfAgreement';

export default function CompanyForm({ provider, setProvider, setSignupStep }) {
    const states = stateArray;
    const { access, refresh, logout } = useAuth();
    const api = useApi(access, refresh, logout);
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        reset,
        setError,
        formState: { errors },
    } = useForm();
    const phone = watch('phone');
    const tempZipCode = watch('tempZipCode');
    const [federalTax, setFederalTax] = useState(null);
    const [address, setAddress] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (!federalTax && provider?.federalTax) {
            reset({
                type: provider.federalTax.type,
                taxId: provider.federalTax.taxId,
            });
            setFederalTax(provider.federalTax);
        }
        if (!address && provider?.address) {
            setAddress(provider.address);
            reset({
                line1: provider.address.line1,
                line2: provider.address.line2,
                city: provider.address.city,
                state: provider.address.state,
                zipCode: provider.address.zipCode,
            });
        }
        if (provider?.primaryEmail) {
            reset({
                email: provider.primaryEmail.email,
            });
        }
        setLoading(false);
    }, [address, federalTax, provider]);

    //handle any input changes to Provider
    const handleProviderChange = (e) => {
        const { name, value } = e.target;
        if (provider.hasOwnProperty(name)) {
            if (errors) {
                delete errors[e.target.name];
            }
            setProvider(value, name);
        }
    };

    // handle any input changes to FederalTax
    const handleFederalTaxChange = (e) => {
        const { name, value } = e.target;
        if (errors) {
            delete errors[e.target.name];
        }
        setFederalTax((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // handle any input changes to Address
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        if (errors) {
            delete errors[e.target.name];
        }
        setAddress((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // handle Email Change
    const handleEmailChange = (e) => {
        setProvider({ email: e.target.value }, 'primaryEmail');
    };

    //check if phone number is valid, and is not already in the list, then add it to the list
    const handleAddPhone = () => {
        const phone = FormatPhoneNumber(getValues('phone')).formattedNumber;
        console.log('phone', phone);
        // phone looks like this: 1234567890 when it should look like this: (123) 456-7890
        if (phone?.length !== 14) {
            return setError('phone', { type: 'custom', message: 'Please enter a valid phone number' });
        } else if (provider?.phones.includes(phone)) {
            return setError('phone', { type: 'custom', message: "You've already added this phone number" });
        }
        if (!provider.primaryPhone.number) {
            // set primary phone if one isn't set
            setProvider({ number: phone }, 'primaryPhone');
        }
        setProvider([...provider.phones, phone], 'phones');
        reset({ phone: '' });
    };

    //submit the form data to the parent component and move to the next step
    const handleCompanyFormSubmit = async (data) => {
        provider.zipCode = data.tempZipCode;
        federalTax.name = provider.name;
        federalTax.address = address;
        // make sure phone number(s) added
        if (!provider?.phones?.length > 0) {
            setError('phone', { type: 'custom', message: 'Please add at least one phone number' });
        }

        setLoading(true);
        let requestMethod = api.authPost;
        let url = '/providers/';
        if (provider?.uuid) {
            requestMethod = api.authPatch;
            url += `${provider.uuid}/`;
        }
        await requestMethod({
            url: url,
            body: {
                preferredPayment: {},
                insurance: {},
                ...provider,
                address: address,
                federalTax: federalTax,
            },
            setError: setError,
        })
            .then((results) => {
                if (results.success) {
                    setProvider(results.response.data);
                    setSignupStep(2);
                } else {
                    console.log(results);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(false);
    };

    const removePhone = (index) => {
        // TODO: check to see if phone is primary phone and remove if it is
        const newPhones = provider.phones.filter((_, i) => i !== index);
        setProvider(newPhones, 'phones');
    };

    return (
        <>
            {termsAccepted === true ? (
                <form
                    onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleCompanyFormSubmit)}
                    className='signup-form-content'
                    noValidate
                >
                    <h4>Company Profile</h4>
                    <p>Please provide us with your company information to successfully verify your identity.</p>
                    <div className='input-group'>
                        <input
                            {...register('name', { required: 'This is required' })}
                            aria-invalid={errors.name ? 'true' : 'false'}
                            type='text'
                            name='name'
                            placeholder='Company Name'
                            className={errors.name ? 'input-box is-invalid' : 'input-box'}
                            value={provider?.name}
                            onChange={handleProviderChange}
                        />
                        {errors.name && (
                            <span role='alert' className='input-error'>
                                {errors.name?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <div className='input-wrapper'>
                            <input
                                {...register('line1', { required: 'This is required' })}
                                aria-invalid={errors.line1 ? 'true' : 'false'}
                                type='text'
                                name='line1'
                                placeholder='Company Address 1'
                                className={errors.line1 ? 'input-box is-invalid' : 'input-box'}
                                value={address?.line1}
                                onChange={handleAddressChange}
                            />
                            <CarLocation className='icon car-icon' />
                        </div>
                        {errors.line1 && (
                            <span role='alert' className='input-error'>
                                {errors.line1?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <div className='input-wrapper'>
                            <input
                                {...register('line2')}
                                aria-invalid={errors.line2 ? 'true' : 'false'}
                                type='text'
                                name='line2'
                                placeholder='Company Address 2 (Optional)'
                                className={errors.line2 ? 'input-box is-invalid' : 'input-box'}
                                value={address?.line2}
                                onChange={handleAddressChange}
                            />
                            <CarLocation className='icon car-icon' />
                        </div>
                        {errors.line2 && (
                            <span role='alert' className='input-error'>
                                {errors.line2?.message}
                            </span>
                        )}
                    </div>
                    <div className='city-state'>
                        <div>
                            <input
                                {...register('city', { required: 'This is required' })}
                                aria-invalid={errors.city ? 'true' : 'false'}
                                type='text'
                                name='city'
                                placeholder='City'
                                className={errors.city ? 'input-box is-invalid' : 'input-box'}
                                value={address?.city}
                                onChange={handleAddressChange}
                            />
                            {errors.city && (
                                <span role='alert' className='input-error'>
                                    {errors.city?.message}
                                </span>
                            )}
                        </div>
                        <div>
                            <select
                                {...register('state', { required: 'This is required' })}
                                aria-invalid={errors.state ? 'true' : 'false'}
                                name='state'
                                className={errors.state ? 'input-box is-invalid' : 'input-box'}
                                value={address?.state}
                                onChange={handleAddressChange}
                                required
                            >
                                <option style={{ display: 'none' }} value=''>
                                    State
                                </option>
                                {states.map((state) => (
                                    <option value={state.abbreviation}>{state.name}</option>
                                ))}
                            </select>
                            {errors.state && (
                                <span role='alert' className='input-error'>
                                    {errors.state?.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='input-group'>
                        <input
                            {...register('zipCode', { required: 'This is required' })}
                            aria-invalid={errors.zipCode ? 'true' : 'false'}
                            type='text'
                            name='zipCode'
                            placeholder='Company Zip Code'
                            onChange={handleAddressChange}
                            className={errors.zipCode ? 'input-box is-invalid' : 'input-box'}
                            value={address?.zipCode}
                        />
                        {errors.zipCode && (
                            <span role='alert' className='input-error'>
                                {errors.zipCode?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <select
                            {...register('type', { required: 'This is required' })}
                            aria-invalid={errors.type ? 'true' : 'false'}
                            name='type'
                            className={errors.type ? 'input-box is-invalid' : 'input-box'}
                            value={federalTax?.type}
                            onChange={handleFederalTaxChange}
                            required
                        >
                            <option value='' style={{ display: 'none' }}>
                                Company Entity
                            </option>
                            <option value='0'>Sole Proprietorship</option>
                            <option value='5'>LLC</option>
                            <option value='1'>Corporation</option>
                        </select>
                        {errors.type && (
                            <span role='alert' className='input-error'>
                                {errors.type?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <input
                            {...register('taxId', { required: 'This is required' })}
                            aria-invalid={errors.taxId ? 'true' : 'false'}
                            type='text'
                            name='taxId'
                            className={errors.taxId ? 'input-box is-invalid' : 'input-box'}
                            placeholder='Company Tax ID'
                            value={federalTax?.taxId}
                            onChange={handleFederalTaxChange}
                        />
                        {errors.taxId && (
                            <span role='alert' className='input-error'>
                                {errors.taxId?.message}
                            </span>
                        )}
                    </div>
                    <div className='input-group'>
                        <div className='input-wrapper'>
                            <input
                                {...register('phone', {
                                    pattern: {
                                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                                        message: 'Please enter a valid phone number',
                                    },
                                    onChange: (e) => FormatPhoneNumber(e.target.value).formattedNumber,
                                })}
                                aria-invalid={errors.phone ? 'true' : 'false'}
                                type='text'
                                className={errors.phone ? 'input-box is-invalid' : 'input-box'}
                                placeholder='Company Phone Numbers'
                            />
                            <PlusBox className='icon' onClick={handleAddPhone} />
                        </div>
                        {errors.phone && (
                            <span role='alert' className='input-error'>
                                {errors.phone?.message}
                            </span>
                        )}
                    </div>
                    {provider?.phones?.length === 0 ? (
                        <></>
                    ) : (
                        <div className='number-box'>
                            {provider?.phones?.map((phoneNumber, index) => (
                                <div className='number-item' key={index} onClick={() => removePhone(index)}>
                                    <p>{phoneNumber?.number ? phoneNumber.number : phoneNumber}</p>
                                    <Delete className='remove-button' />
                                </div>
                            ))}
                        </div>
                    )}
                    <div className='input-group'>
                        <input
                            {...register('email', { required: 'This is required' })}
                            aria-invalid={errors.email ? 'true' : 'false'}
                            type='text'
                            name='email'
                            className={errors.email ? 'input-box is-invalid' : 'input-box'}
                            placeholder='Company Email Address'
                            value={provider?.primaryEmail?.email}
                            onChange={handleEmailChange}
                        />
                        {errors.email && (
                            <span role='alert' className='input-error'>
                                {errors.email?.message}
                            </span>
                        )}
                    </div>
                    <div className='number-box-2'>
                        <Button text={'Enter the zip code of your base of operations'} className='outlined-button' />
                    </div>
                    <div className='input-group'>
                        <div className='input-wrapper'>
                            <input
                                {...register('tempZipCode', {
                                    required: 'This is required',
                                    pattern: {
                                        value: /^[0-9]{5}$/im,
                                        message: 'Please enter a valid zip code',
                                    },
                                    maxLength: {
                                        value: 5,
                                        message: 'Zip code must be 5 characters long',
                                    },
                                })}
                                type='text'
                                placeholder='Zip Code'
                                aria-invalid={errors.tempZipCode ? 'true' : 'false'}
                                className={errors.tempZipCode ? 'input-box is-invalid' : 'input-box'}
                            />
                        </div>
                        {errors.tempZipCode && (
                            <span role='alert' className='input-error'>
                                {errors.tempZipCode?.message}
                            </span>
                        )}
                    </div>

                    <Button
                        text={loading ? '' : 'Continue'}
                        icon={2}
                        className='red-button'
                        type='submit'
                        customIcon={loading ? <ScaleLoader color='white' /> : null}
                    />
                </form>
            ) : (
                <ProviderTerms setTermsAccepted={setTermsAccepted} />
            )}
        </>
    );
}
