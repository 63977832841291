import { useState, useContext, useEffect } from 'react';
import './signupCompany.scss';
import { toast } from 'react-toastify';

import { ReactComponent as Company } from '../../assets/svg/company.svg';
import { ReactComponent as Driver } from '../../assets/svg/driver.svg';
import { ReactComponent as Schedule } from '../../assets/svg/schedule.svg';
import { ReactComponent as Document } from '../../assets/svg/document.svg';

import CompanySignup from '../../components/companySignup/companyForm';
import DriverForm from '../../components/companySignup/driverForm';
import CompanySchedule from '../../components/companySignup/companySchedule';
import CompanyDocuments from '../../components/companySignup/companyDocuments';
import { useApi } from '../../hooks/api';
import ScaleLoader from 'react-spinners/ScaleLoader';

const SignupCompany = ({ provider, setProvider, authContext }) => {
    const { access, refresh, logout } = authContext;
    const api = useApi(access, refresh, logout);
    const [drivers, setDrivers] = useState([]);
    const [signupStep, setSignupStep] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (drivers.length === 0 && provider?.uuid) {
            getProviderDrivers();
        }
        if (!signupStep) {
            moveToSavedStep();
        }
        setLoading(false);
    }, [provider]);

    const changeStep = (newStep) => {
        setSignupStep(newStep);
    };

    const moveToSavedStep = () => {
        if (provider.schedule && Object.keys(provider.schedule).length !== 0) {
            // if schedule is set
            setSignupStep(4);
        } else if (drivers.length > 0) {
            // if drivers set
            setSignupStep(3);
        } else if (typeof provider.federalTax.type === 'number' && drivers.length === 0) {
            // if federal tax set, but no drivers
            setSignupStep(2);
        } else {
            setSignupStep(1);
        }
    };

    const handleDriversChange = (data) => {
        setDrivers(data);
    };

    const getProviderDrivers = async () => {
        if (provider?.uuid) {
            await api
                .authGet({
                    url: `/drivers/?provider__uuid=${provider.uuid}`,
                })
                .then((results) => {
                    if (results.success) {
                        setDrivers(results.response.data.results);
                    } else {
                        console.log(results);
                        setDrivers([]);
                    }
                });
        } else {
            setDrivers([]);
        }
    };

    return loading ? (
        <ScaleLoader color='#FF6600' loading={loading} size={150} />
    ) : (
        <div className='signup-form'>
            <div className='progress-bar'>
                <div className={signupStep === 1 ? 'progress-bar-item progress-bar-item-active' : 'progress-bar-item progress-bar-item-finished'} onClick={() => setSignupStep(1)}>
                    <Company />
                    {signupStep === 1 && <p>Company</p>}
                </div>
                <div
                    onClick={() => {
                        signupStep >= 2 ? setSignupStep(2) : toast.info('Please fill this form and click Proceed');
                    }}
                    className={signupStep === 1 ? 'progress-bar-item' : signupStep === 2 ? 'progress-bar-item progress-bar-item-active' : 'progress-bar-item progress-bar-item-finished'}
                >
                    <Driver />
                    {signupStep === 2 && <p>Driver</p>}
                </div>
                <div
                    onClick={() => {
                        signupStep >= 3 ? setSignupStep(3) : toast.info('Please fill this form and click Proceed');
                    }}
                    className={signupStep === 3 ? 'progress-bar-item progress-bar-item-active' : signupStep === 4 ? 'progress-bar-item progress-bar-item-finished' : 'progress-bar-item'}
                >
                    <Schedule />
                    {signupStep === 3 && <p>Schedule</p>}
                </div>
                <div
                    onClick={() => {
                        signupStep < 4 && toast.info('Please Complete all the previous forms first');
                    }}
                    className={signupStep === 4 ? 'progress-bar-item progress-bar-item-active' : 'progress-bar-item'}
                >
                    <Document />
                    {signupStep === 4 && <p>Documents</p>}
                </div>
            </div>

            {signupStep === 1 && <CompanySignup provider={provider} setProvider={setProvider} setSignupStep={changeStep} />}

            {signupStep === 2 && <DriverForm provider={provider} drivers={drivers} setDrivers={handleDriversChange} setSignupStep={changeStep} />}

            {signupStep === 3 && <CompanySchedule provider={provider} setProvider={setProvider} setSignupStep={changeStep} />}

            {signupStep === 4 && <CompanyDocuments provider={provider} setProvider={setProvider} setSignupStep={changeStep} />}
        </div>
    );
};

export default SignupCompany;
