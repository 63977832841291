import React, { useState, useEffect } from 'react';
import Button from '../includes/button';
import { ReactComponent as RightArrow } from '../../assets/svg/rightarrow.svg';
import { ScaleLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { useApi } from '../../hooks/api';

export default function LocationForm({ moveStep, order, handleOrderChange }) {
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: order?.vehicle?.customer?.firstName,
            lastName: order?.vehicle?.customer?.lastName,
            email: order?.vehicle?.customer?.primaryEmail?.email,
        },
    });
    const [customer, setCustomer] = useState(
        order?.vehicle?.customer || {
            firstName: null,
            lastName: null,
            primaryEmail: {
                email: null,
            },
        }
    );

    useEffect(() => {
        reset(customer);
    }, [customer]);

    const handleFormSubmit = async (data) => {
        setLoading(true);
        const customerData = {
            firstName: data.firstName,
            lastName: data.lastName,
            primaryEmail: {
                ...customer.primaryEmail,
                email: data.email,
            },
        };
        let apiMethod = api.post;
        let url = '/customers/';
        let body = { ...customer, ...customerData };
        if (customer?.uuid) {
            apiMethod = api.patch;
            url += `${customer.uuid}/`;
            body = customerData;
        }
        await apiMethod({ url: url, body: body, setError: setError })
            .then((results) => {
                if (results.success) {
                    handleOrderChange({
                        data: { vehicle: { ...order.vehicle, customer: results.response.data.uuid } },
                        setError: setError,
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    return (
        <form onSubmit={loading ? (e) => e.preventDefault() : handleSubmit(handleFormSubmit)}>
            <h2>Fill Out Your Personal Information</h2>
            <div className='personal-info'>
                <div className='input-group'>
                    <input
                        {...register('firstName', {
                            required: 'This is required',
                            value: customer.firstName,
                        })}
                        aria-invalid={errors.firstName ? 'true' : 'false'}
                        className={errors.firstName ? 'input-box is-invalid' : 'input-box'}
                        type='text'
                        name='firstName'
                        placeholder='First Name'
                    />
                    {errors.firstName && (
                        <span role='alert' className='input-error'>
                            {errors.firstName?.message}
                        </span>
                    )}
                </div>
                <div className='input-group'>
                    <input
                        {...register('lastName', {
                            required: 'This is required',
                            value: customer.lastName,
                        })}
                        aria-invalid={errors.lastName ? 'true' : 'false'}
                        className={errors.lastName ? 'input-box is-invalid' : 'input-box'}
                        type='text'
                        name='lastName'
                        placeholder='Last Name'
                    />
                    {errors.lastName && (
                        <span role='alert' className='input-error'>
                            {errors.lastName?.message}
                        </span>
                    )}
                </div>
                <div className='input-group'>
                    <input
                        {...register('email', {
                            required: 'This is required',
                            value: customer.primaryEmail.email,
                        })}
                        aria-invalid={errors.email ? 'true' : 'false'}
                        className={errors.email ? 'input-box is-invalid' : 'input-box'}
                        type='text'
                        name='email'
                        placeholder='Email'
                    />
                    {errors.email && (
                        <span role='alert' className='input-error'>
                            {errors.email?.message}
                        </span>
                    )}
                </div>
            </div>
            <div className='action-buttons'>
                <Button className='outlined-button' action={loading ? () => {} : () => moveStep(-1)} text='Back' icon={1} />
                <Button
                    className='red-button'
                    text={loading ? '' : 'Next'}
                    customIcon={loading ? <ScaleLoader color='white' /> : <RightArrow className='icon' />}
                    type='submit'
                />
            </div>
        </form>
    );
}
