import './home.scss';
import { useEffect, useState } from 'react';
import ServiceType from '../../components/serviceType';
// import { ReactComponent as UserSvg } from '../../assets/svg/user.svg';
// import { ReactComponent as BusinessSvg }from '../../assets/svg/business.svg';
import Hero from '../../assets/img/hero.jpg';
// import Button from '../../components/includes/button';
import { useNavigate } from 'react-router-dom';
import { useSchema } from '../../contexts/schema';
import { set } from 'react-hook-form';

export default function Home({ selectedSchema }) {
    const navigate = useNavigate();
    const { setChosenSchema } = useSchema();
    const [userType, setUserType] = useState(true);

    const changeUser = (type) => {
        setUserType(type);
    };

    useEffect(() => {
        selectedSchema ? setChosenSchema(selectedSchema) : setChosenSchema('defaultSchema');
    }, []);

    return (
        <div className='page-container'>
            <div className='hero-section'>
                <div className='welcome-section light-shadow'>
                    <h1 className='headline1'>Stranded? Need some help?</h1>
                    {/* <p>For emergencies call 911.</p> */}
                    <ServiceType userType={userType} />
                    {/* <div className="user-selector">
                        <Button className={userType ? 'red-button' : 'outlined-button outlined-icon'} action={()=>changeUser(true)} text=" Customer" customIcon={<UserSvg />} />
                        <Button type={userType ?  'outlined-button' : 'red-button red-icon'} action={()=>{changeUser(false)}} text="Business" customIcon={<BusinessSvg />} />
                    </div> */}
                </div>
                <div className='user-selector red-button'>
                    <p>
                        <strong>ARE YOU A ROADSIDE SERVICE PROVIDER?</strong>
                    </p>

                    <button
                        className='register-button outlined-button action-button'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/we-want-you');
                        }}
                    >
                        CLICK HERE
                    </button>
                </div>
            </div>
            <img src={Hero} alt='' className='hero' />
        </div>
    );
}
